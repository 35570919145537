/** Below styles are legacy needs to be refactored **/
/** Below styles are legacy needs to be refactored **/
/** Below styles are legacy needs to be refactored **/
.bar {
  visibility: hidden; }

.input {
  padding: 0;
  height: 32px; }

.inputElement {
  font-family: "Open Sans";
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: #19496B;
  border: 1px solid #C5CBD3;
  color: #19496B;
  border-radius: 5px;
  padding: 8px 10px;
  width: 100%;
  height: 32px; }
  .inputElement a {
    font-size: 12px; }
  .inputElement:focus {
    border-color: #0d5a91; }
  .inputElement::placeholder {
    color: #c5cbd3; }

.label {
  display: none; }

.counter {
  display: none; }

.disabled {
  background-color: #f4f7fc; }
  .disabled .inputElement {
    border-style: solid; }
