.renewContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 0px; }
  .renewContainer h1 {
    color: #0d5a91;
    margin: 0; }
  .renewContainer h3 {
    font-family: 'Open Sans', sans-serif;
    color: #929fb0;
    margin: 0; }
  .renewContainer [data-id="superLlama"] {
    height: 280px;
    margin-top: 20px;
    margin-left: 55px; }
  .renewContainer h6 {
    text-align: center;
    font-family: 'Open Sans', sans-serif;
    margin-top: 0;
    color: #929fb0; }
  .renewContainer [data-id="help"] {
    margin-top: 20px; }
  .renewContainer a {
    text-decoration: none;
    font-size: inherit;
    margin-left: 10px;
    font-weight: bold; }
