.marketplace_container {
  min-height: 100vh;
  display: flex; }
  .marketplace_container > div {
    padding: 20px;
    flex: 1; }
    @media only screen and (max-width: 64rem) {
      .marketplace_container > div:first-child {
        display: none; } }

.description {
  color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center; }
  .description > * {
    width: 450px; }
  .description svg {
    margin-top: 120px;
    width: 35px;
    border: 1px solid white;
    border-radius: 50%;
    padding: 5px;
    margin-bottom: 20px; }
  .description h1 {
    margin-bottom: 160px; }
  .description div {
    font-size: 28px;
    margin-top: auto;
    color: #fff;
    font-family: 'Khand'; }

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: contain; }
  .form > * {
    width: 450px; }
  .form a {
    text-decoration: none;
    font-weight: bold; }

.success {
  text-align: center; }
  .success svg {
    width: 35px;
    margin-bottom: 12px; }
    .success svg path {
      fill: #2577b0; }

.fieldset {
  display: flex;
  flex-direction: column; }
  .fieldset label + input {
    margin-top: 4px; }
  .fieldset + .fieldset, .fieldset + button {
    margin-top: 12px; }
  .fieldset input {
    font-size: 16px;
    border: 1px solid #c5cbd3;
    color: #0d5a91;
    border-radius: 5px;
    padding: 8px 10px;
    text-shadow: none; }
    .fieldset input:focus {
      border-color: #2577b0;
      outline: none; }
  .fieldset button {
    width: 100%; }
    .fieldset button:not([disabled]) {
      background-color: #0d5a91 !important;
      color: #fff !important; }

.has_error {
  color: #ff7d7d;
  margin-top: 8px; }
