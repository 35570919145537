.container {
  height: 100vh;
  width: 100vw;
  display: grid;
  gap: 10px;
  grid-template-columns: 1fr 420px 1fr;
  grid-template-rows: 1fr auto auto auto auto 1fr; }
  .container [data-id="mountains"] {
    position: fixed;
    bottom: 0;
    z-index: -1;
    width: 100%; }

.llama_icon {
  grid-column: 2;
  grid-row: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* width is defined by message width+padding*/ }
  .llama_icon > svg path:last-child {
    fill: #19496b !important; }

.content {
  display: flex;
  flex-direction: column;
  grid-column: 2;
  grid-row: 3; }

.header {
  padding: 10px 0px;
  font-size: 32px;
  color: #0D5A91;
  text-align: center; }

.sub_header {
  padding: 0px 0px 30px 0px;
  font-size: 12px;
  color: #0D5A91;
  text-align: center; }

.form_container {
  border: 1px solid #c5cbd3;
  box-shadow: 0px 2px 4px rgba(100, 100, 100, 0.5);
  padding: 0px 64px;
  background-color: #fff;
  border-radius: 5px; }

.form {
  display: flex;
  flex-direction: column; }

.field {
  width: 100%;
  margin-top: 16px; }
  .field:first-child {
    margin-top: 32px; }
  .field:last-child {
    margin-bottom: 40px; }
  .field > .title {
    color: #929fb0;
    font-size: 12px; }
  .field > .control {
    font-size: 12px;
    margin-right: 10px; }
    .field > .control > span {
      font-size: 12px; }
    .field > .control > input {
      font-size: 12px;
      color: #19496B; }
    .field > .control > div > input {
      font-size: 12px;
      color: #19496B; }
  .field .dropdown_custom > ul {
    font-size: 12px; }
  .field .dropdown_custom > div:after {
    top: 8px; }
  .field .custom_check {
    display: inline; }
  .field .custom_check_label {
    display: inline-block;
    font-size: 12px;
    width: 200px;
    white-space: pre-line;
    color: #939598;
    margin-top: -4px; }
    .field .custom_check_label > .bold {
      font-size: 12px;
      color: #19496B; }
    .field .custom_check_label > a {
      text-decoration: none; }
      .field .custom_check_label > a:visited {
        color: #19496B; }
      .field .custom_check_label > a:hover {
        color: #19496B; }
      .field .custom_check_label > a:active {
        color: #19496B; }
  .field > .button {
    margin: 0 auto; }

.error_message {
  font-size: 12px;
  color: #FF7D7D; }

.error {
  grid-column: 2;
  grid-row: 3;
  color: #FF7D7D;
  font-size: 16px;
  text-align: center; }
