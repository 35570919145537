/** Below styles are legacy needs to be refactored **/
.field {
  display: flex;
  align-items: center;
  margin-bottom: 0; }

.check {
  width: 18px;
  border-radius: 5px;
  border-color: #c5cbd3;
  border-width: 1px; }
  .check.checked {
    background-color: white;
    border-color: #c5cbd3; }
    .check.checked::after {
      border-color: #0d5a91;
      margin-top: 2px;
      margin-left: 0.3px; }

.circle .check {
  border-radius: 50%; }

.text {
  color: #939598;
  font-family: 'Open Sans', sans-serif; }
