.dialog {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: auto !important;
  max-width: 100vw !important;
  background-color: transparent !important; }
  .dialog .loader_parent {
    background: transparent; }

.overlay {
  background-color: #539ac2 !important; }

.loader_parent {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #539ac2;
  opacity: 0.7;
  z-index: 9999;
  display: flex;
  align-items: center; }

.loader {
  background: #ffffff;
  -webkit-animation: load1 1s infinite ease-in-out;
  animation: load1 1s infinite ease-in-out;
  width: 1em;
  height: 4em;
  opacity: 1;
  color: #ffffff;
  text-indent: -9999em;
  margin: 88px auto;
  position: relative;
  font-size: 11px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s; }
  .loader:before, .loader:after {
    background: #ffffff;
    -webkit-animation: load1 1s infinite ease-in-out;
    animation: load1 1s infinite ease-in-out;
    width: 1em;
    height: 4em; }
  .loader:before, .loader:after {
    position: absolute;
    top: 0;
    content: ''; }
  .loader:before {
    left: -1.5em;
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s; }
  .loader:after {
    left: 1.5em; }

@-webkit-keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em; }
  40% {
    box-shadow: 0 -2em;
    height: 5em; } }

@keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em; }
  40% {
    box-shadow: 0 -2em;
    height: 5em; } }
