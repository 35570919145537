/** Below styles are legacy needs to be refactored **/
.dropdown {
  height: 32px;
  background-color: #fff;
  padding: 0px; }
  .dropdown .value {
    height: 32px;
    padding: 0px; }
    .dropdown .value > input {
      height: 32px;
      padding: 0px;
      padding-left: 8px;
      font-size: 12px;
      color: #19496B; }
    .dropdown .value:after {
      display: none; }
  .dropdown .selected {
    color: #19496B; }
  .dropdown .values {
    margin-top: 32px; }
    .dropdown .values :hover {
      color: #19496B; }
    .dropdown .values li {
      display: flex;
      align-items: center;
      padding: 0px;
      font-size: 12px;
      min-height: 32px;
      padding: 8px; }
