.container {
  height: 100vh;
  width: 100vw;
  display: grid;
  gap: 32px;
  grid-template-columns: 1fr auto 1fr;
  grid-template-rows: 1fr auto auto 1fr; }

.llama_icon {
  grid-column: 2;
  grid-row: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* width is defined by message width+padding*/ }
  .llama_icon > svg path:last-child {
    fill: #19496b !important; }

.message {
  grid-column: 2;
  grid-row: 3;
  padding: 0px 64px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: #4d9ed6;
  padding-bottom: 20px; }
  .message a:link,
  .message a:visited,
  .message a:active,
  .message a:hover {
    font-size: 14px;
    color: #4d9ed6;
    font-weight: 600; }
