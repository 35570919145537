.container {
  width: 100%;
  padding: 0;
  margin: 0;
  text-align: center; }
  .container .item {
    list-style: none; }
    .container .item span {
      display: block;
      color: #969696;
      font-size: 1.3rem; }
      .container .item span.number {
        color: #425863;
        margin-top: 20px;
        font-size: 4rem;
        line-height: 4rem; }
        .container .item span.number.subtitle_margin {
          margin-top: 0; }
