:root {
  --palette-red-50: rgb(255, 235, 238);
  --palette-red-100: rgb(255, 205, 210);
  --palette-red-200: rgb(239, 154, 154);
  --palette-red-300: rgb(229, 115, 115);
  --palette-red-400: rgb(239, 83, 80);
  --palette-red-500: rgb(244, 67, 54);
  --palette-red-600: rgb(229, 57, 53);
  --palette-red-700: rgb(211, 47, 47);
  --palette-red-800: rgb(198, 40, 40);
  --palette-red-900: rgb(183, 28, 28);
  --palette-red-a100: rgb(255, 138, 128);
  --palette-red-a200: rgb(255, 82, 82);
  --palette-red-a400: rgb(255, 23, 68);
  --palette-red-a700: rgb(213, 0, 0);
  --palette-pink-50: rgb(252, 228, 236);
  --palette-pink-100: rgb(248, 187, 208);
  --palette-pink-200: rgb(244, 143, 177);
  --palette-pink-300: rgb(240, 98, 146);
  --palette-pink-400: rgb(236, 64, 122);
  --palette-pink-500: rgb(233, 30, 99);
  --palette-pink-600: rgb(216, 27, 96);
  --palette-pink-700: rgb(194, 24, 91);
  --palette-pink-800: rgb(173, 20, 87);
  --palette-pink-900: rgb(136, 14, 79);
  --palette-pink-a100: rgb(255, 128, 171);
  --palette-pink-a200: rgb(255, 64, 129);
  --palette-pink-a400: rgb(245, 0, 87);
  --palette-pink-a700: rgb(197, 17, 98);
  --palette-purple-50: rgb(243, 229, 245);
  --palette-purple-100: rgb(225, 190, 231);
  --palette-purple-200: rgb(206, 147, 216);
  --palette-purple-300: rgb(186, 104, 200);
  --palette-purple-400: rgb(171, 71, 188);
  --palette-purple-500: rgb(156, 39, 176);
  --palette-purple-600: rgb(142, 36, 170);
  --palette-purple-700: rgb(123, 31, 162);
  --palette-purple-800: rgb(106, 27, 154);
  --palette-purple-900: rgb(74, 20, 140);
  --palette-purple-a100: rgb(234, 128, 252);
  --palette-purple-a200: rgb(224, 64, 251);
  --palette-purple-a400: rgb(213, 0, 249);
  --palette-purple-a700: rgb(170, 0, 255);
  --palette-deep-purple-50: rgb(237, 231, 246);
  --palette-deep-purple-100: rgb(209, 196, 233);
  --palette-deep-purple-200: rgb(179, 157, 219);
  --palette-deep-purple-300: rgb(149, 117, 205);
  --palette-deep-purple-400: rgb(126, 87, 194);
  --palette-deep-purple-500: rgb(103, 58, 183);
  --palette-deep-purple-600: rgb(94, 53, 177);
  --palette-deep-purple-700: rgb(81, 45, 168);
  --palette-deep-purple-800: rgb(69, 39, 160);
  --palette-deep-purple-900: rgb(49, 27, 146);
  --palette-deep-purple-a100: rgb(179, 136, 255);
  --palette-deep-purple-a200: rgb(124, 77, 255);
  --palette-deep-purple-a400: rgb(101, 31, 255);
  --palette-deep-purple-a700: rgb(98, 0, 234);
  --palette-indigo-50: rgb(232, 234, 246);
  --palette-indigo-100: rgb(197, 202, 233);
  --palette-indigo-200: rgb(159, 168, 218);
  --palette-indigo-300: rgb(121, 134, 203);
  --palette-indigo-400: rgb(92, 107, 192);
  --palette-indigo-500: rgb(63, 81, 181);
  --palette-indigo-600: rgb(57, 73, 171);
  --palette-indigo-700: rgb(48, 63, 159);
  --palette-indigo-800: rgb(40, 53, 147);
  --palette-indigo-900: rgb(26, 35, 126);
  --palette-indigo-a100: rgb(140, 158, 255);
  --palette-indigo-a200: rgb(83, 109, 254);
  --palette-indigo-a400: rgb(61, 90, 254);
  --palette-indigo-a700: rgb(48, 79, 254);
  --palette-blue-50: rgb(227, 242, 253);
  --palette-blue-100: rgb(187, 222, 251);
  --palette-blue-200: rgb(144, 202, 249);
  --palette-blue-300: rgb(100, 181, 246);
  --palette-blue-400: rgb(66, 165, 245);
  --palette-blue-500: rgb(33, 150, 243);
  --palette-blue-600: rgb(30, 136, 229);
  --palette-blue-700: rgb(25, 118, 210);
  --palette-blue-800: rgb(21, 101, 192);
  --palette-blue-900: rgb(13, 71, 161);
  --palette-blue-a100: rgb(130, 177, 255);
  --palette-blue-a200: rgb(68, 138, 255);
  --palette-blue-a400: rgb(41, 121, 255);
  --palette-blue-a700: rgb(41, 98, 255);
  --palette-light-blue-50: rgb(225, 245, 254);
  --palette-light-blue-100: rgb(179, 229, 252);
  --palette-light-blue-200: rgb(129, 212, 250);
  --palette-light-blue-300: rgb(79, 195, 247);
  --palette-light-blue-400: rgb(41, 182, 246);
  --palette-light-blue-500: rgb(3, 169, 244);
  --palette-light-blue-600: rgb(3, 155, 229);
  --palette-light-blue-700: rgb(2, 136, 209);
  --palette-light-blue-800: rgb(2, 119, 189);
  --palette-light-blue-900: rgb(1, 87, 155);
  --palette-light-blue-a100: rgb(128, 216, 255);
  --palette-light-blue-a200: rgb(64, 196, 255);
  --palette-light-blue-a400: rgb(0, 176, 255);
  --palette-light-blue-a700: rgb(0, 145, 234);
  --palette-cyan-50: rgb(224, 247, 250);
  --palette-cyan-100: rgb(178, 235, 242);
  --palette-cyan-200: rgb(128, 222, 234);
  --palette-cyan-300: rgb(77, 208, 225);
  --palette-cyan-400: rgb(38, 198, 218);
  --palette-cyan-500: rgb(0, 188, 212);
  --palette-cyan-600: rgb(0, 172, 193);
  --palette-cyan-700: rgb(0, 151, 167);
  --palette-cyan-800: rgb(0, 131, 143);
  --palette-cyan-900: rgb(0, 96, 100);
  --palette-cyan-a100: rgb(132, 255, 255);
  --palette-cyan-a200: rgb(24, 255, 255);
  --palette-cyan-a400: rgb(0, 229, 255);
  --palette-cyan-a700: rgb(0, 184, 212);
  --palette-teal-50: rgb(224, 242, 241);
  --palette-teal-100: rgb(178, 223, 219);
  --palette-teal-200: rgb(128, 203, 196);
  --palette-teal-300: rgb(77, 182, 172);
  --palette-teal-400: rgb(38, 166, 154);
  --palette-teal-500: rgb(0, 150, 136);
  --palette-teal-600: rgb(0, 137, 123);
  --palette-teal-700: rgb(0, 121, 107);
  --palette-teal-800: rgb(0, 105, 92);
  --palette-teal-900: rgb(0, 77, 64);
  --palette-teal-a100: rgb(167, 255, 235);
  --palette-teal-a200: rgb(100, 255, 218);
  --palette-teal-a400: rgb(29, 233, 182);
  --palette-teal-a700: rgb(0, 191, 165);
  --palette-green-50: rgb(232, 245, 233);
  --palette-green-100: rgb(200, 230, 201);
  --palette-green-200: rgb(165, 214, 167);
  --palette-green-300: rgb(129, 199, 132);
  --palette-green-400: rgb(102, 187, 106);
  --palette-green-500: rgb(76, 175, 80);
  --palette-green-600: rgb(67, 160, 71);
  --palette-green-700: rgb(56, 142, 60);
  --palette-green-800: rgb(46, 125, 50);
  --palette-green-900: rgb(27, 94, 32);
  --palette-green-a100: rgb(185, 246, 202);
  --palette-green-a200: rgb(105, 240, 174);
  --palette-green-a400: rgb(0, 230, 118);
  --palette-green-a700: rgb(0, 200, 83);
  --palette-light-green-50: rgb(241, 248, 233);
  --palette-light-green-100: rgb(220, 237, 200);
  --palette-light-green-200: rgb(197, 225, 165);
  --palette-light-green-300: rgb(174, 213, 129);
  --palette-light-green-400: rgb(156, 204, 101);
  --palette-light-green-500: rgb(139, 195, 74);
  --palette-light-green-600: rgb(124, 179, 66);
  --palette-light-green-700: rgb(104, 159, 56);
  --palette-light-green-800: rgb(85, 139, 47);
  --palette-light-green-900: rgb(51, 105, 30);
  --palette-light-green-a100: rgb(204, 255, 144);
  --palette-light-green-a200: rgb(178, 255, 89);
  --palette-light-green-a400: rgb(118, 255, 3);
  --palette-light-green-a700: rgb(100, 221, 23);
  --palette-lime-50: rgb(249, 251, 231);
  --palette-lime-100: rgb(240, 244, 195);
  --palette-lime-200: rgb(230, 238, 156);
  --palette-lime-300: rgb(220, 231, 117);
  --palette-lime-400: rgb(212, 225, 87);
  --palette-lime-500: rgb(205, 220, 57);
  --palette-lime-600: rgb(192, 202, 51);
  --palette-lime-700: rgb(175, 180, 43);
  --palette-lime-800: rgb(158, 157, 36);
  --palette-lime-900: rgb(130, 119, 23);
  --palette-lime-a100: rgb(244, 255, 129);
  --palette-lime-a200: rgb(238, 255, 65);
  --palette-lime-a400: rgb(198, 255, 0);
  --palette-lime-a700: rgb(174, 234, 0);
  --palette-yellow-50: rgb(255, 253, 231);
  --palette-yellow-100: rgb(255, 249, 196);
  --palette-yellow-200: rgb(255, 245, 157);
  --palette-yellow-300: rgb(255, 241, 118);
  --palette-yellow-400: rgb(255, 238, 88);
  --palette-yellow-500: rgb(255, 235, 59);
  --palette-yellow-600: rgb(253, 216, 53);
  --palette-yellow-700: rgb(251, 192, 45);
  --palette-yellow-800: rgb(249, 168, 37);
  --palette-yellow-900: rgb(245, 127, 23);
  --palette-yellow-a100: rgb(255, 255, 141);
  --palette-yellow-a200: rgb(255, 255, 0);
  --palette-yellow-a400: rgb(255, 234, 0);
  --palette-yellow-a700: rgb(255, 214, 0);
  --palette-amber-50: rgb(255, 248, 225);
  --palette-amber-100: rgb(255, 236, 179);
  --palette-amber-200: rgb(255, 224, 130);
  --palette-amber-300: rgb(255, 213, 79);
  --palette-amber-400: rgb(255, 202, 40);
  --palette-amber-500: rgb(255, 193, 7);
  --palette-amber-600: rgb(255, 179, 0);
  --palette-amber-700: rgb(255, 160, 0);
  --palette-amber-800: rgb(255, 143, 0);
  --palette-amber-900: rgb(255, 111, 0);
  --palette-amber-a100: rgb(255, 229, 127);
  --palette-amber-a200: rgb(255, 215, 64);
  --palette-amber-a400: rgb(255, 196, 0);
  --palette-amber-a700: rgb(255, 171, 0);
  --palette-orange-50: rgb(255, 243, 224);
  --palette-orange-100: rgb(255, 224, 178);
  --palette-orange-200: rgb(255, 204, 128);
  --palette-orange-300: rgb(255, 183, 77);
  --palette-orange-400: rgb(255, 167, 38);
  --palette-orange-500: rgb(255, 152, 0);
  --palette-orange-600: rgb(251, 140, 0);
  --palette-orange-700: rgb(245, 124, 0);
  --palette-orange-800: rgb(239, 108, 0);
  --palette-orange-900: rgb(230, 81, 0);
  --palette-orange-a100: rgb(255, 209, 128);
  --palette-orange-a200: rgb(255, 171, 64);
  --palette-orange-a400: rgb(255, 145, 0);
  --palette-orange-a700: rgb(255, 109, 0);
  --palette-deep-orange-50: rgb(251, 233, 231);
  --palette-deep-orange-100: rgb(255, 204, 188);
  --palette-deep-orange-200: rgb(255, 171, 145);
  --palette-deep-orange-300: rgb(255, 138, 101);
  --palette-deep-orange-400: rgb(255, 112, 67);
  --palette-deep-orange-500: rgb(255, 87, 34);
  --palette-deep-orange-600: rgb(244, 81, 30);
  --palette-deep-orange-700: rgb(230, 74, 25);
  --palette-deep-orange-800: rgb(216, 67, 21);
  --palette-deep-orange-900: rgb(191, 54, 12);
  --palette-deep-orange-a100: rgb(255, 158, 128);
  --palette-deep-orange-a200: rgb(255, 110, 64);
  --palette-deep-orange-a400: rgb(255, 61, 0);
  --palette-deep-orange-a700: rgb(221, 44, 0);
  --palette-brown-50: rgb(239, 235, 233);
  --palette-brown-100: rgb(215, 204, 200);
  --palette-brown-200: rgb(188, 170, 164);
  --palette-brown-300: rgb(161, 136, 127);
  --palette-brown-400: rgb(141, 110, 99);
  --palette-brown-500: rgb(121, 85, 72);
  --palette-brown-600: rgb(109, 76, 65);
  --palette-brown-700: rgb(93, 64, 55);
  --palette-brown-800: rgb(78, 52, 46);
  --palette-brown-900: rgb(62, 39, 35);
  --palette-grey-50: rgb(250, 250, 250);
  --palette-grey-100: rgb(245, 245, 245);
  --palette-grey-200: rgb(238, 238, 238);
  --palette-grey-300: rgb(224, 224, 224);
  --palette-grey-400: rgb(189, 189, 189);
  --palette-grey-500: rgb(158, 158, 158);
  --palette-grey-600: rgb(117, 117, 117);
  --palette-grey-700: rgb(97, 97, 97);
  --palette-grey-800: rgb(66, 66, 66);
  --palette-grey-900: rgb(33, 33, 33);
  --palette-blue-grey-50: rgb(236, 239, 241);
  --palette-blue-grey-100: rgb(207, 216, 220);
  --palette-blue-grey-200: rgb(176, 190, 197);
  --palette-blue-grey-300: rgb(144, 164, 174);
  --palette-blue-grey-400: rgb(120, 144, 156);
  --palette-blue-grey-500: rgb(96, 125, 139);
  --palette-blue-grey-600: rgb(84, 110, 122);
  --palette-blue-grey-700: rgb(69, 90, 100);
  --palette-blue-grey-800: rgb(55, 71, 79);
  --palette-blue-grey-900: rgb(38, 50, 56);
  --color-black: rgb(0, 0, 0);
  --color-white: rgb(255, 255, 255);
  --color-dark-contrast: var(--color-white);
  --color-light-contrast: var(--color-black); }

:root {
  --color-divider: var(--palette-grey-200);
  --color-background: var(--color-white);
  --color-text: var(--palette-grey-900);
  --color-text-secondary: var(--palette-grey-600);
  --color-primary: var(--palette-indigo-500);
  --color-primary-dark: var(--palette-indigo-700);
  --color-accent: var(--palette-pink-a200);
  --color-accent-dark: var(--palette-pink-700);
  --color-primary-contrast: var(--color-dark-contrast);
  --color-accent-contrast: var(--color-dark-contrast);
  --unit: 10px;
  --preferred-font: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  --font-size: calc(1.6 * var(--unit));
  --font-size-tiny: calc(1.2 * var(--unit));
  --font-size-small: calc(1.4 * var(--unit));
  --font-size-normal: var(--font-size);
  --font-size-big: calc(1.8 * var(--unit));
  --font-weight-thin: 300;
  --font-weight-normal: 400;
  --font-weight-semi-bold: 500;
  --font-weight-bold: 700;
  --shadow-2p:
    0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  --shadow-3p:
    0 3px 4px 0 rgba(0, 0, 0, 0.14),
    0 3px 3px -2px rgba(0, 0, 0, 0.2),
    0 1px 8px 0 rgba(0, 0, 0, 0.12);
  --shadow-4p:
    0 4px 5px 0 rgba(0, 0, 0, 0.14),
    0 1px 10px 0 rgba(0, 0, 0, 0.12),
    0 2px 4px -1px rgba(0, 0, 0, 0.2);
  --shadow-6p:
    0 6px 10px 0 rgba(0, 0, 0, 0.14),
    0 1px 18px 0 rgba(0, 0, 0, 0.12),
    0 3px 5px -1px rgba(0, 0, 0, 0.2);
  --shadow-8p:
    0 8px 10px 1px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12),
    0 5px 5px -3px rgba(0, 0, 0, 0.2);
  --shadow-16p:
    0 16px 24px 2px rgba(0, 0, 0, 0.14),
    0 6px 30px 5px rgba(0, 0, 0, 0.12),
    0 8px 10px -5px rgba(0, 0, 0, 0.2);
  --shadow-key-umbra-opacity: 0.2;
  --shadow-key-penumbra-opacity: 0.14;
  --shadow-ambient-shadow-opacity: 0.12;
  --zdepth-shadow-1: 0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.24);
  --zdepth-shadow-2: 0 3px 10px rgba(0, 0, 0, 0.16), 0 3px 10px rgba(0, 0, 0, 0.23);
  --zdepth-shadow-3: 0 10px 30px rgba(0, 0, 0, 0.19), 0 6px 10px rgba(0, 0, 0, 0.23);
  --zdepth-shadow-4: 0 14px 45px rgba(0, 0, 0, 0.25), 0 10px 18px rgba(0, 0, 0, 0.22);
  --zdepth-shadow-5: 0 19px 60px rgba(0, 0, 0, 0.3), 0 15px 20px rgba(0, 0, 0, 0.22);
  --animation-duration: 0.35s;
  --animation-delay: calc(var(--animation-duration) / 5);
  --animation-curve-fast-out-slow-in: cubic-bezier(0.4, 0, 0.2, 1);
  --animation-curve-linear-out-slow-in: cubic-bezier(0, 0, 0.2, 1);
  --animation-curve-fast-out-linear-in: cubic-bezier(0.4, 0, 1, 1);
  --animation-curve-default: var(--animation-curve-fast-out-slow-in);
  --z-index-highest: 300;
  --z-index-higher: 200;
  --z-index-high: 100;
  --z-index-normal: 1;
  --z-index-low: -100;
  --z-index-lower: -200
; }

.dialog {
  overflow: auto; }

:global {
  /* Change Autocomplete styles in Chrome*/ }
  :global input:-webkit-autofill,
  :global input:-webkit-autofill:hover,
  :global input:-webkit-autofill:focus
textarea:-webkit-autofill,
  :global textarea:-webkit-autofill:hover
textarea:-webkit-autofill:focus,
  :global select:-webkit-autofill,
  :global select:-webkit-autofill:hover,
  :global select:-webkit-autofill:focus {
    box-shadow: 0 0 0px 1000px transparent inset;
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: #19496b; }
  :global .git-hash {
    position: absolute;
    bottom: 0;
    z-index: 10;
    right: 0;
    font-size: 16px;
    padding: 0 6px 2px 0; }
  :global .sm-snackbar {
    height: 3.5rem;
    width: 64%; }
    :global .sm-snackbar span, :global .sm-snackbar button {
      font-size: 14px;
      padding: 0 1.5rem; }
    :global .sm-snackbar button {
      font-size: 12px;
      height: 50px;
      line-height: 50px; }
  :global .cursor-pointer {
    cursor: pointer; }

.container {
  width: 352px;
  border-radius: 8px;
  background-color: #fff;
  height: calc(44vh - 3.92857rem - 8px); }
  .container .sequence_container {
    flex-shrink: 0;
    position: relative;
    width: 96.8%;
    padding: 0 0 8px 15px;
    border-bottom: .5px solid #C5CBD3; }
    .container .sequence_container > span {
      font-size: 12px;
      font-weight: bold;
      color: #19496b; }
    .container .sequence_container:last-child {
      border-bottom: none; }
      .container .sequence_container:last-child:only-child {
        border-bottom: .5px solid #C5CBD3; }
    .container .sequence_container .sequence_title {
      display: inline-block;
      font-weight: bold;
      font-size: 12px;
      width: 88%;
      padding-top: 5px;
      text-align: justify; }
      .container .sequence_container .sequence_title .loading_status {
        position: absolute;
        right: 5px;
        top: 5px; }
        .container .sequence_container .sequence_title .loading_status.clock {
          width: 30px; }
        .container .sequence_container .sequence_title .loading_status.spinner {
          width: 40px;
          right: 0;
          top: 0px; }
    .container .sequence_container.syntax {
      display: flex;
      padding-top: 8px; }
      .container .sequence_container.syntax > span {
        font-size: 12px;
        max-width: calc(100% - 2rem);
        flex-grow: 1; }
        .container .sequence_container.syntax > span + span {
          flex-grow: 0;
          margin-right: 1rem; }
    .container .sequence_container .yaml_button {
      position: absolute;
      top: 8px;
      right: 16px;
      width: 1.4rem;
      height: 1.4rem;
      line-height: .8rem;
      background-color: transparent; }
      .container .sequence_container .yaml_button svg {
        height: 16px;
        width: 16px; }
        .container .sequence_container .yaml_button svg path {
          fill: #65DBAE; }
      .container .sequence_container .yaml_button.success svg path {
        fill: #65DBAE; }
      .container .sequence_container .yaml_button.error svg path {
        fill: #FF7D7D; }
      .container .sequence_container .yaml_button.pending svg path {
        fill: #929fb0; }
  .container .empty_sequence_container {
    flex-grow: 1;
    position: relative;
    width: 96.8%;
    padding: 0 0 8px 15px;
    border-bottom: .5px solid #C5CBD3; }
    .container .empty_sequence_container .sequence_title {
      display: inline-block;
      font-size: 12px;
      width: 88%;
      padding-top: 5px;
      text-align: justify; }
  .container .title {
    width: 100%;
    display: inline-block;
    text-align: center; }
    .container .title span {
      width: 100%;
      display: inline-block;
      font-size: 12px;
      color: #787878;
      font-weight: bold; }
      .container .title span.secondary_text {
        font-size: 12px;
        font-weight: normal;
        white-space: nowrap;
        word-break: break-all; }
  .container .validation_result_row:first-child {
    margin-top: 4px; }
  .container .validation_result_row > div {
    width: 90%;
    display: inline-block;
    text-align: left;
    margin-bottom: 5px; }
    .container .validation_result_row > div .row_icon_status {
      position: absolute;
      right: 16px; }
      .container .validation_result_row > div .row_icon_status img {
        height: 2rem; }
        .container .validation_result_row > div .row_icon_status img.clock {
          height: 1.5rem; }
      .container .validation_result_row > div .row_icon_status > div svg {
        width: 18px; }
      .container .validation_result_row > div .row_icon_status.success {
        color: #65DBAE; }
        .container .validation_result_row > div .row_icon_status.success > div svg path {
          fill: #65DBAE; }
      .container .validation_result_row > div .row_icon_status.error {
        color: #FF7D7D; }
        .container .validation_result_row > div .row_icon_status.error > div svg path {
          fill: #FF7D7D; }
      .container .validation_result_row > div .row_icon_status.pending {
        color: #FF7D7D; }
        .container .validation_result_row > div .row_icon_status.pending > div svg path {
          fill: #929fb0; }
    .container .validation_result_row > div span {
      font-size: 12px;
      display: block;
      font-weight: 600; }
      .container .validation_result_row > div span.yaml_result {
        margin-left: 20px;
        font-weight: normal;
        text-overflow: ellipsis;
        overflow-x: hidden; }
      .container .validation_result_row > div span.error_yaml_result {
        margin-left: 40px;
        font-weight: normal; }
  .container .result_text_row {
    margin-bottom: 4px; }
  .container .align_center {
    text-align: center; }
  .container .result_text {
    font-size: 12px;
    background-color: #EBEBEB;
    padding: 5px 0 6px 15px;
    height: 2.5rem;
    line-height: 2.5rem;
    margin-left: -10px; }
    .container .result_text.success {
      color: #65DBAE; }
    .container .result_text.error {
      color: #FF7D7D; }

.scrollable {
  height: calc(44vh - 3.928571428571429rem - 8px);
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-flow: column; }

@media (max-width: 1366px) {
  .scrollable {
    height: calc(31vh - 3.928571428571429rem - 8px); } }
