/** Below styles are legacy needs to be refactored **/
.component {
  display: grid;
  grid-template-columns: auto 1fr;
  padding-left: 16px; }
  .component svg {
    width: 36px;
    path-fill: #19496B; }
  .component > :first-child {
    grid-row: 1/span 2;
    padding-right: 12px;
    display: flex;
    align-items: center;
    justify-content: center; }
