.leftPanel {
  position: relative;
  display: flex;
  color: white;
  flex-direction: column;
  line-height: normal;
  align-items: center;
  text-align: center; }
  @media only screen and (max-width: 64rem) {
    .leftPanel {
      display: none; } }
  .leftPanel [data-id="details"] {
    position: absolute;
    top: 23%; }
  .leftPanel div {
    font-family: 'Khand'; }
  .leftPanel [data-id="icon"] {
    width: 47px;
    margin-bottom: 20px; }
  .leftPanel [data-id="message1"] {
    font-family: 'Raleway';
    font-size: 21px;
    color: #fff;
    margin-bottom: 28px; }
  .leftPanel [data-id="message2"],
  .leftPanel [data-id="message3"] {
    font-size: 42px;
    text-transform: uppercase;
    color: #fff;
    line-height: 100%; }
  .leftPanel [data-id="message3"] {
    font-weight: bold;
    color: #fff;
    margin-top: 5px; }
  .leftPanel [data-id="companyName"] {
    position: fixed;
    font-size: 28px;
    bottom: 10px;
    color: #fff; }

.rightPanel {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  color: #929fb0;
  line-height: 30px;
  font-size: 1.1rem; }
  .rightPanel * {
    font-family: 'Open Sans';
    font-size: 14px; }
  .rightPanel [data-id="container"] {
    display: flex;
    flex-direction: column; }
  @media only screen and (max-width: 64rem) {
    .rightPanel {
      width: 100vw !important; } }
  .rightPanel [data-id="icon"] {
    height: 60px;
    margin-bottom: 50px;
    flex-shrink: 0; }
    .rightPanel [data-id="icon"] path:last-child {
      fill: #19496b !important; }
  .rightPanel section {
    width: 80%;
    padding: 30px 0 20px 0;
    margin-left: 10%; }
    .rightPanel section ul {
      font-size: 14px; }
  .rightPanel a {
    color: #0d5a91;
    text-decoration: none;
    font-weight: bold; }
  .rightPanel .sendEmail {
    margin-top: 12px;
    text-align: center; }
  .rightPanel [data-id="center"] {
    text-align: center; }
  .rightPanel [data-id="logout"] {
    text-align: left;
    font-size: 12px;
    text-align: center; }
  .rightPanel [data-id="go-skill"] {
    text-align: left;
    font-size: 14px;
    text-align: center; }
  .rightPanel [data-id="sendEmail"] {
    font-size: 14px;
    text-align: center;
    margin-top: 5px; }
  .rightPanel [data-id="message1"] {
    text-align: center;
    font-size: 2.5rem;
    color: #0d5a91;
    font-family: "Khand";
    margin-bottom: 20px;
    text-transform: capitalize; }
  .rightPanel [data-id="mountains"] {
    position: fixed;
    bottom: 0;
    z-index: -1;
    width: 50%; }
    @media only screen and (max-width: 64rem) {
      .rightPanel [data-id="mountains"] {
        width: 100%; } }
