.component {
  display: grid;
  gap: 16px 12px;
  grid-template-columns: 1fr minmax(15%, auto) minmax(15%, auto) 100px minmax(40px, auto); }

.grid_header {
  display: flex;
  justify-content: center;
  align-items: center; }

.grid_header_second_group {
  margin-top: 8px; }

.column_center {
  display: flex;
  justify-content: center;
  align-items: center; }

.empty_row {
  grid-column: 1/-1;
  padding-top: 16px;
  display: flex;
  justify-content: center;
  align-items: center; }
