.secondary {
  height: 25px !important;
  font-size: 12px !important;
  line-height: 12px !important;
  border-radius: 3px !important; }
  .secondary svg {
    height: 12px !important; }
  .secondary.iconRight {
    flex-direction: row-reverse; }
    .secondary.iconRight [data-react-toolbox="font-icon"] {
      margin-right: 0;
      margin-left: 6px; }
