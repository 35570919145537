.button[data-id="bespoken-button"] {
  display: flex;
  font-family: 'Open Sans', sans-serif;
  text-transform: capitalize;
  border-radius: 5px;
  height: 40px;
  line-height: 40px;
  font-size: 16px;
  border: 1px solid #0d5a91;
  min-width: 150px; }
  .button[data-id="bespoken-button"] .icon {
    display: flex; }
    .button[data-id="bespoken-button"] .icon svg {
      display: flex;
      align-items: baseline;
      height: 16px; }
  .button[data-id="bespoken-button"] .ripple {
    color: #19496b; }
  .button[data-id="bespoken-button"].accent {
    background-color: #0d5a91;
    color: white; }
    .button[data-id="bespoken-button"].accent svg path, .button[data-id="bespoken-button"].accent svg rect {
      fill: white; }
    .button[data-id="bespoken-button"].accent[disabled] {
      background-color: #b4c0cf;
      border-color: #b4c0cf;
      color: #f4f7fc; }
    .button[data-id="bespoken-button"].accent:hover:not([disabled]) {
      background-color: #2577b0;
      border-color: #2577b0; }
  .button[data-id="bespoken-button"]:not(.accent) {
    background-color: white;
    color: #0d5a91; }
    .button[data-id="bespoken-button"]:not(.accent) svg path, .button[data-id="bespoken-button"]:not(.accent) svg rect {
      fill: #0d5a91; }
    .button[data-id="bespoken-button"]:not(.accent)[disabled] {
      border-color: #b4c0cf;
      color: #c5cbd3; }
      .button[data-id="bespoken-button"]:not(.accent)[disabled] svg path, .button[data-id="bespoken-button"]:not(.accent)[disabled] svg rect {
        fill: #b4c0cf; }
    .button[data-id="bespoken-button"]:not(.accent):hover:not([disabled]) {
      background-color: white;
      border-color: #2577b0;
      color: #2577b0; }
      .button[data-id="bespoken-button"]:not(.accent):hover:not([disabled]) svg path, .button[data-id="bespoken-button"]:not(.accent):hover:not([disabled]) svg rect {
        fill: #2577b0; }
