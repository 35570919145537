.inOutInput {
  display: none;
  transform: scale(0);
  opacity: 0;
  transition: transform .4s ease-in-out, opacity .3s ease-in-out; }
  .inOutInput.active {
    display: block;
    transform: scale(1);
    opacity: 1;
    animation: anim .4s ease-in-out; }

@keyframes anim {
  0% {
    display: none;
    opacity: 0; }
  1% {
    display: block;
    opacity: 0;
    transform: scale(0); }
  100% {
    opacity: 1;
    transform: scale(1); } }
