.header {
  background-color: #19496b; }

.day.active span {
  background-color: #539ac2; }

.button {
  color: #19496a !important; }

.calendar {
  color: #19496b;
  font-family: 'Open Sans', sans-serif;
  font-size: 400;
  font-size: 14px;
  line-height: 14px; }
