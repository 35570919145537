.container {
  position: relative; }

.link_container {
  margin-left: 2%;
  display: flex;
  flex-flow: column nowrap; }
  .link_container a {
    font-weight: 400;
    text-decoration: none;
    color: #939598;
    padding-bottom: 5px; }
    .link_container a:hover {
      cursor: pointer; }

.input {
  width: 11.8rem;
  height: 30px;
  padding: 0 0 0 10px;
  font-weight: 300;
  font-size: 16px;
  border: 1px solid #aaa;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px; }

.inputFocused {
  outline: none; }

.inputOpen {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

.suggestionsContainer {
  display: none; }

.suggestionsContainerOpen {
  display: block;
  position: absolute;
  top: 31px;
  width: 12.5rem;
  max-height: 350px;
  overflow-y: auto;
  border: 1px solid #aaa;
  background-color: #fff;
  font-family: "Helvetica", sans-serif;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2; }

.suggestionsList {
  margin: 0;
  padding: 0;
  list-style-type: none; }

.suggestion {
  cursor: pointer;
  padding: 5px 10px; }

.suggestionHighlighted {
  background-color: #ddd;
  padding: 5px 10px; }

.title_container .inline_edit_container {
  position: relative;
  width: 100%; }

.title_container .container {
  position: absolute;
  top: 15px;
  left: 130px; }

.title_container .soure_name_placeholder {
  position: absolute;
  top: 19px;
  left: 130px;
  font-size: 1.8rem;
  color: #596170; }

.title_container span {
  color: #939598;
  white-space: nowrap; }
  .title_container span + span {
    top: 70px;
    color: #596170;
    font-size: 1.6rem;
    letter-spacing: -1px; }
    .title_container span + span::after {
      display: none; }

.title_container .input {
  padding: 0;
  border: 0;
  font-size: 1.8rem;
  color: #596170;
  opacity: 0; }
  .title_container .input::placeholder {
    color: #fff; }

.title_container .inputFocused {
  opacity: 1; }

@media (max-width: 1366px) {
  .input {
    width: 170px; }
  .suggestionsContainerOpen {
    width: 180px; } }

@media (max-width: 1024px) {
  .input {
    width: 170px; }
  .suggestionsContainerOpen {
    width: 180px; } }

@media (max-width: 767px) {
  .input {
    width: 170px; }
  .suggestionsContainerOpen {
    width: 180px; } }

@media (max-width: 320px) {
  .input {
    width: 170px; }
  .suggestionsContainerOpen {
    width: 180px; } }
