/** Below styles are legacy needs to be refactored **/
/** Below styles are legacy needs to be refactored **/
/** Below styles are legacy needs to be refactored **/
.leftPanel {
  position: relative;
  display: flex;
  color: white;
  flex-direction: column;
  line-height: normal;
  align-items: center;
  text-align: center; }
  @media only screen and (max-width: 64rem) {
    .leftPanel {
      display: none; } }
  .leftPanel [data-id="details"] {
    position: absolute;
    top: 15%; }
  .leftPanel div {
    font-family: 'Khand'; }
  .leftPanel [data-id="icon"] {
    width: 35px;
    border: 1px solid white;
    border-radius: 50%;
    padding: 5px;
    margin-bottom: 20px; }
  .leftPanel [data-id="message1"] {
    font-family: 'Raleway';
    font-size: 21px;
    color: #fff;
    margin-bottom: 28px; }
  .leftPanel [data-id="message2"],
  .leftPanel [data-id="message3"] {
    font-size: 42px;
    text-transform: uppercase;
    color: #fff;
    line-height: 100%; }
  .leftPanel [data-id="message3"] {
    font-weight: bold;
    color: #fff;
    margin-top: 5px; }
  .leftPanel [data-id="companyName"] {
    position: fixed;
    font-size: 28px;
    bottom: 10px;
    color: #fff; }

.rightPanel {
  background-color: transparent;
  height: 100vh; }
  @media only screen and (max-width: 64rem) {
    .rightPanel {
      width: 100vw; } }
  .rightPanel section {
    display: grid;
    grid-template-rows: 1fr auto 1fr auto;
    width: 80%;
    height: 100%;
    max-width: 550px;
    margin-left: 10%; }
  .rightPanel [data-id="mountains"] {
    position: fixed;
    bottom: 0;
    z-index: -1;
    width: 50%; }
    @media only screen and (max-width: 64rem) {
      .rightPanel [data-id="mountains"] {
        width: 100%; } }
  .rightPanel [data-id="container"] {
    height: 100%;
    padding-top: unset;
    padding-bottom: unset; }

.survey_text_input {
  display: flex;
  gap: 2px;
  flex-direction: column; }
  .survey_text_input > .title {
    color: #929fb0;
    font-size: 14px; }
  .survey_text_input > .control > input {
    font-size: 14px; }
  .survey_text_input > .control.error > input {
    border: 1px solid #FF0F00; }

.survey_other_input {
  display: flex;
  gap: 2px;
  flex-direction: column; }
  .survey_other_input > .title {
    color: #929fb0;
    font-size: 14px; }
  .survey_other_input > .control > input {
    font-size: 14px; }
  .survey_other_input > .control ul > li {
    font-size: 14px; }

.survey_jobtitle {
  display: flex;
  gap: 2px;
  flex-direction: column; }
  .survey_jobtitle > .title {
    color: #929fb0;
    font-size: 14px; }
  .survey_jobtitle > .control {
    margin-right: 10px;
    flex-grow: 1;
    flex-shrink: 0; }
    .survey_jobtitle > .control > div {
      flex-shrink: 1; }
      .survey_jobtitle > .control > div > input {
        font-size: 14px;
        color: #0d5a91; }

.survey_emailerror {
  display: flex;
  gap: 2px;
  flex-direction: column; }
  .survey_emailerror > .title {
    font-size: 14px; }
  .survey_emailerror > .control {
    color: #ff7d7d;
    font-size: 14px;
    line-height: 18px; }

.form_container {
  grid-row: 2;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px 23px; }
  .form_container > .llama_icon {
    grid-column: 1 / -1;
    padding-bottom: 50px; }
    .form_container > .llama_icon > svg {
      height: 60px; }
      .form_container > .llama_icon > svg path:last-child {
        fill: #19496b !important; }
  .form_container > .page_title {
    grid-column: 1 / -1;
    font-family: "Khand";
    font-size: 2.5rem;
    line-height: 100%;
    text-transform: capitalize;
    color: #0d5a91; }
  .form_container > .takes_just_30_title {
    grid-column: 1 / -1;
    font-size: 14px;
    color: #539ac2;
    padding-bottom: 20px; }
  .form_container > .hear_about_question_title {
    grid-column: 1 / -1;
    color: #539ac2;
    padding-top: 20px; }
  .form_container > .buttons {
    grid-column: 1 / -1;
    padding-top: 20px;
    display: flex;
    justify-content: flex-end; }

.alt_text {
  grid-row: 4;
  color: #939598;
  padding-bottom: 28px; }
  .alt_text * {
    font-family: "Open Sans";
    font-size: 12px; }
