/** Below styles are legacy needs to be refactored **/
.needHelp {
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.16) 2px 2px 5px;
  padding: 24px 0;
  border-radius: 15px;
  color: black;
  width: 336px; }
  .needHelp .section [data-id="innerSection"] {
    padding: 0 16px; }
  .needHelp .section hr {
    height: 0.5px;
    margin: 0;
    border-top: 1px solid #eaedf2;
    margin: 16px 0; }
  .needHelp .section [data-id="sectionName"] {
    color: #0d5a91;
    font-weight: bold;
    font-size: 12px;
    margin-bottom: 16px; }
  .needHelp .section .item {
    display: flex;
    align-items: flex-start;
    margin-bottom: 16px;
    cursor: pointer; }
    .needHelp .section .item [data-id="icon"] {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 8px;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      background-color: #939598; }
      .needHelp .section .item [data-id="icon"] svg {
        width: 16px; }
      .needHelp .section .item [data-id="icon"] path, .needHelp .section .item [data-id="icon"] rect {
        fill: white; }
    .needHelp .section .item [data-id="primaryText"] {
      font-size: 14px;
      color: #939598;
      font-weight: bold;
      line-heidwwght: 100%; }
    .needHelp .section .item [data-id="secondaryText"] {
      color: #939598;
      font-size: 12px; }
    .needHelp .section .item:hover [data-id="primaryText"] {
      color: #0d5a91; }
    .needHelp .section .item:hover [data-id="secondaryText"] {
      color: #939598; }
    .needHelp .section .item:hover [data-id="icon"] {
      background-color: #0d5a91; }
    .needHelp .section .item:last-child {
      margin-bottom: 0; }
