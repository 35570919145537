/** Below styles are legacy needs to be refactored **/
.component {
  display: grid;
  grid-template-columns: 1fr minmax(32px, auto);
  gap: 16px 4px; }
  .component [class~="RemoveIconButton"] {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s .15s, opacity .15s ease-in; }
  .component [class~="Input"]:hover + [class~="RemoveIconButton"],
  .component [class~="RemoveIconButton"]:hover {
    visibility: visible;
    opacity: 1;
    transition: opacity .05s ease-out; }

.add_link_button {
  grid-column-start: 1;
  display: flex;
  justify-content: flex-end; }
  .add_link_button [class~='TextBlueLabelSmall'] {
    color: #C5CBD3; }
    .add_link_button [class~='TextBlueLabelSmall']:hover {
      color: #19496B; }
