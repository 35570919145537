.innerknob {
  background-color: #0d5a91 !important;
  height: 18px;
  width: 18px;
  border: unset !important; }

.innerprogress {
  background-color: #c5cbd3; }

.snaps {
  left: -3px; }
  .snaps::after {
    background-color: #c5cbd3;
    height: 7px;
    width: 7px;
    border-radius: 50%;
    position: absolute;
    top: -3px;
    right: 0; }

.snap {
  background-color: #c5cbd3; }
  .snap::after {
    background-color: #c5cbd3;
    height: 7px;
    width: 7px;
    border-radius: 50%;
    top: -3px;
    position: absolute; }
