.container {
  margin: 0;
  padding: 0; }
  .container li {
    list-style: none;
    text-align: center;
    width: 35%;
    margin: auto;
    color: #535E79; }
    .container li span {
      display: block;
      font-size: 2rem;
      font-weight: bold; }
      .container li span + span {
        font-size: .9rem;
        line-height: .9rem;
        font-weight: normal;
        color: #AFAFAF; }
  .container.small {
    margin-top: 15px; }
    .container.small li {
      width: 40%; }
      .container.small li span {
        font-size: 1.6rem; }
        .container.small li span + span {
          font-size: .9rem;
          line-height: .9rem; }
