/** Below styles are legacy needs to be refactored **/
:root {
  --palette-red-50: rgb(255, 235, 238);
  --palette-red-100: rgb(255, 205, 210);
  --palette-red-200: rgb(239, 154, 154);
  --palette-red-300: rgb(229, 115, 115);
  --palette-red-400: rgb(239, 83, 80);
  --palette-red-500: rgb(244, 67, 54);
  --palette-red-600: rgb(229, 57, 53);
  --palette-red-700: rgb(211, 47, 47);
  --palette-red-800: rgb(198, 40, 40);
  --palette-red-900: rgb(183, 28, 28);
  --palette-red-a100: rgb(255, 138, 128);
  --palette-red-a200: rgb(255, 82, 82);
  --palette-red-a400: rgb(255, 23, 68);
  --palette-red-a700: rgb(213, 0, 0);
  --palette-pink-50: rgb(252, 228, 236);
  --palette-pink-100: rgb(248, 187, 208);
  --palette-pink-200: rgb(244, 143, 177);
  --palette-pink-300: rgb(240, 98, 146);
  --palette-pink-400: rgb(236, 64, 122);
  --palette-pink-500: rgb(233, 30, 99);
  --palette-pink-600: rgb(216, 27, 96);
  --palette-pink-700: rgb(194, 24, 91);
  --palette-pink-800: rgb(173, 20, 87);
  --palette-pink-900: rgb(136, 14, 79);
  --palette-pink-a100: rgb(255, 128, 171);
  --palette-pink-a200: rgb(255, 64, 129);
  --palette-pink-a400: rgb(245, 0, 87);
  --palette-pink-a700: rgb(197, 17, 98);
  --palette-purple-50: rgb(243, 229, 245);
  --palette-purple-100: rgb(225, 190, 231);
  --palette-purple-200: rgb(206, 147, 216);
  --palette-purple-300: rgb(186, 104, 200);
  --palette-purple-400: rgb(171, 71, 188);
  --palette-purple-500: rgb(156, 39, 176);
  --palette-purple-600: rgb(142, 36, 170);
  --palette-purple-700: rgb(123, 31, 162);
  --palette-purple-800: rgb(106, 27, 154);
  --palette-purple-900: rgb(74, 20, 140);
  --palette-purple-a100: rgb(234, 128, 252);
  --palette-purple-a200: rgb(224, 64, 251);
  --palette-purple-a400: rgb(213, 0, 249);
  --palette-purple-a700: rgb(170, 0, 255);
  --palette-deep-purple-50: rgb(237, 231, 246);
  --palette-deep-purple-100: rgb(209, 196, 233);
  --palette-deep-purple-200: rgb(179, 157, 219);
  --palette-deep-purple-300: rgb(149, 117, 205);
  --palette-deep-purple-400: rgb(126, 87, 194);
  --palette-deep-purple-500: rgb(103, 58, 183);
  --palette-deep-purple-600: rgb(94, 53, 177);
  --palette-deep-purple-700: rgb(81, 45, 168);
  --palette-deep-purple-800: rgb(69, 39, 160);
  --palette-deep-purple-900: rgb(49, 27, 146);
  --palette-deep-purple-a100: rgb(179, 136, 255);
  --palette-deep-purple-a200: rgb(124, 77, 255);
  --palette-deep-purple-a400: rgb(101, 31, 255);
  --palette-deep-purple-a700: rgb(98, 0, 234);
  --palette-indigo-50: rgb(232, 234, 246);
  --palette-indigo-100: rgb(197, 202, 233);
  --palette-indigo-200: rgb(159, 168, 218);
  --palette-indigo-300: rgb(121, 134, 203);
  --palette-indigo-400: rgb(92, 107, 192);
  --palette-indigo-500: rgb(63, 81, 181);
  --palette-indigo-600: rgb(57, 73, 171);
  --palette-indigo-700: rgb(48, 63, 159);
  --palette-indigo-800: rgb(40, 53, 147);
  --palette-indigo-900: rgb(26, 35, 126);
  --palette-indigo-a100: rgb(140, 158, 255);
  --palette-indigo-a200: rgb(83, 109, 254);
  --palette-indigo-a400: rgb(61, 90, 254);
  --palette-indigo-a700: rgb(48, 79, 254);
  --palette-blue-50: rgb(227, 242, 253);
  --palette-blue-100: rgb(187, 222, 251);
  --palette-blue-200: rgb(144, 202, 249);
  --palette-blue-300: rgb(100, 181, 246);
  --palette-blue-400: rgb(66, 165, 245);
  --palette-blue-500: rgb(33, 150, 243);
  --palette-blue-600: rgb(30, 136, 229);
  --palette-blue-700: rgb(25, 118, 210);
  --palette-blue-800: rgb(21, 101, 192);
  --palette-blue-900: rgb(13, 71, 161);
  --palette-blue-a100: rgb(130, 177, 255);
  --palette-blue-a200: rgb(68, 138, 255);
  --palette-blue-a400: rgb(41, 121, 255);
  --palette-blue-a700: rgb(41, 98, 255);
  --palette-light-blue-50: rgb(225, 245, 254);
  --palette-light-blue-100: rgb(179, 229, 252);
  --palette-light-blue-200: rgb(129, 212, 250);
  --palette-light-blue-300: rgb(79, 195, 247);
  --palette-light-blue-400: rgb(41, 182, 246);
  --palette-light-blue-500: rgb(3, 169, 244);
  --palette-light-blue-600: rgb(3, 155, 229);
  --palette-light-blue-700: rgb(2, 136, 209);
  --palette-light-blue-800: rgb(2, 119, 189);
  --palette-light-blue-900: rgb(1, 87, 155);
  --palette-light-blue-a100: rgb(128, 216, 255);
  --palette-light-blue-a200: rgb(64, 196, 255);
  --palette-light-blue-a400: rgb(0, 176, 255);
  --palette-light-blue-a700: rgb(0, 145, 234);
  --palette-cyan-50: rgb(224, 247, 250);
  --palette-cyan-100: rgb(178, 235, 242);
  --palette-cyan-200: rgb(128, 222, 234);
  --palette-cyan-300: rgb(77, 208, 225);
  --palette-cyan-400: rgb(38, 198, 218);
  --palette-cyan-500: rgb(0, 188, 212);
  --palette-cyan-600: rgb(0, 172, 193);
  --palette-cyan-700: rgb(0, 151, 167);
  --palette-cyan-800: rgb(0, 131, 143);
  --palette-cyan-900: rgb(0, 96, 100);
  --palette-cyan-a100: rgb(132, 255, 255);
  --palette-cyan-a200: rgb(24, 255, 255);
  --palette-cyan-a400: rgb(0, 229, 255);
  --palette-cyan-a700: rgb(0, 184, 212);
  --palette-teal-50: rgb(224, 242, 241);
  --palette-teal-100: rgb(178, 223, 219);
  --palette-teal-200: rgb(128, 203, 196);
  --palette-teal-300: rgb(77, 182, 172);
  --palette-teal-400: rgb(38, 166, 154);
  --palette-teal-500: rgb(0, 150, 136);
  --palette-teal-600: rgb(0, 137, 123);
  --palette-teal-700: rgb(0, 121, 107);
  --palette-teal-800: rgb(0, 105, 92);
  --palette-teal-900: rgb(0, 77, 64);
  --palette-teal-a100: rgb(167, 255, 235);
  --palette-teal-a200: rgb(100, 255, 218);
  --palette-teal-a400: rgb(29, 233, 182);
  --palette-teal-a700: rgb(0, 191, 165);
  --palette-green-50: rgb(232, 245, 233);
  --palette-green-100: rgb(200, 230, 201);
  --palette-green-200: rgb(165, 214, 167);
  --palette-green-300: rgb(129, 199, 132);
  --palette-green-400: rgb(102, 187, 106);
  --palette-green-500: rgb(76, 175, 80);
  --palette-green-600: rgb(67, 160, 71);
  --palette-green-700: rgb(56, 142, 60);
  --palette-green-800: rgb(46, 125, 50);
  --palette-green-900: rgb(27, 94, 32);
  --palette-green-a100: rgb(185, 246, 202);
  --palette-green-a200: rgb(105, 240, 174);
  --palette-green-a400: rgb(0, 230, 118);
  --palette-green-a700: rgb(0, 200, 83);
  --palette-light-green-50: rgb(241, 248, 233);
  --palette-light-green-100: rgb(220, 237, 200);
  --palette-light-green-200: rgb(197, 225, 165);
  --palette-light-green-300: rgb(174, 213, 129);
  --palette-light-green-400: rgb(156, 204, 101);
  --palette-light-green-500: rgb(139, 195, 74);
  --palette-light-green-600: rgb(124, 179, 66);
  --palette-light-green-700: rgb(104, 159, 56);
  --palette-light-green-800: rgb(85, 139, 47);
  --palette-light-green-900: rgb(51, 105, 30);
  --palette-light-green-a100: rgb(204, 255, 144);
  --palette-light-green-a200: rgb(178, 255, 89);
  --palette-light-green-a400: rgb(118, 255, 3);
  --palette-light-green-a700: rgb(100, 221, 23);
  --palette-lime-50: rgb(249, 251, 231);
  --palette-lime-100: rgb(240, 244, 195);
  --palette-lime-200: rgb(230, 238, 156);
  --palette-lime-300: rgb(220, 231, 117);
  --palette-lime-400: rgb(212, 225, 87);
  --palette-lime-500: rgb(205, 220, 57);
  --palette-lime-600: rgb(192, 202, 51);
  --palette-lime-700: rgb(175, 180, 43);
  --palette-lime-800: rgb(158, 157, 36);
  --palette-lime-900: rgb(130, 119, 23);
  --palette-lime-a100: rgb(244, 255, 129);
  --palette-lime-a200: rgb(238, 255, 65);
  --palette-lime-a400: rgb(198, 255, 0);
  --palette-lime-a700: rgb(174, 234, 0);
  --palette-yellow-50: rgb(255, 253, 231);
  --palette-yellow-100: rgb(255, 249, 196);
  --palette-yellow-200: rgb(255, 245, 157);
  --palette-yellow-300: rgb(255, 241, 118);
  --palette-yellow-400: rgb(255, 238, 88);
  --palette-yellow-500: rgb(255, 235, 59);
  --palette-yellow-600: rgb(253, 216, 53);
  --palette-yellow-700: rgb(251, 192, 45);
  --palette-yellow-800: rgb(249, 168, 37);
  --palette-yellow-900: rgb(245, 127, 23);
  --palette-yellow-a100: rgb(255, 255, 141);
  --palette-yellow-a200: rgb(255, 255, 0);
  --palette-yellow-a400: rgb(255, 234, 0);
  --palette-yellow-a700: rgb(255, 214, 0);
  --palette-amber-50: rgb(255, 248, 225);
  --palette-amber-100: rgb(255, 236, 179);
  --palette-amber-200: rgb(255, 224, 130);
  --palette-amber-300: rgb(255, 213, 79);
  --palette-amber-400: rgb(255, 202, 40);
  --palette-amber-500: rgb(255, 193, 7);
  --palette-amber-600: rgb(255, 179, 0);
  --palette-amber-700: rgb(255, 160, 0);
  --palette-amber-800: rgb(255, 143, 0);
  --palette-amber-900: rgb(255, 111, 0);
  --palette-amber-a100: rgb(255, 229, 127);
  --palette-amber-a200: rgb(255, 215, 64);
  --palette-amber-a400: rgb(255, 196, 0);
  --palette-amber-a700: rgb(255, 171, 0);
  --palette-orange-50: rgb(255, 243, 224);
  --palette-orange-100: rgb(255, 224, 178);
  --palette-orange-200: rgb(255, 204, 128);
  --palette-orange-300: rgb(255, 183, 77);
  --palette-orange-400: rgb(255, 167, 38);
  --palette-orange-500: rgb(255, 152, 0);
  --palette-orange-600: rgb(251, 140, 0);
  --palette-orange-700: rgb(245, 124, 0);
  --palette-orange-800: rgb(239, 108, 0);
  --palette-orange-900: rgb(230, 81, 0);
  --palette-orange-a100: rgb(255, 209, 128);
  --palette-orange-a200: rgb(255, 171, 64);
  --palette-orange-a400: rgb(255, 145, 0);
  --palette-orange-a700: rgb(255, 109, 0);
  --palette-deep-orange-50: rgb(251, 233, 231);
  --palette-deep-orange-100: rgb(255, 204, 188);
  --palette-deep-orange-200: rgb(255, 171, 145);
  --palette-deep-orange-300: rgb(255, 138, 101);
  --palette-deep-orange-400: rgb(255, 112, 67);
  --palette-deep-orange-500: rgb(255, 87, 34);
  --palette-deep-orange-600: rgb(244, 81, 30);
  --palette-deep-orange-700: rgb(230, 74, 25);
  --palette-deep-orange-800: rgb(216, 67, 21);
  --palette-deep-orange-900: rgb(191, 54, 12);
  --palette-deep-orange-a100: rgb(255, 158, 128);
  --palette-deep-orange-a200: rgb(255, 110, 64);
  --palette-deep-orange-a400: rgb(255, 61, 0);
  --palette-deep-orange-a700: rgb(221, 44, 0);
  --palette-brown-50: rgb(239, 235, 233);
  --palette-brown-100: rgb(215, 204, 200);
  --palette-brown-200: rgb(188, 170, 164);
  --palette-brown-300: rgb(161, 136, 127);
  --palette-brown-400: rgb(141, 110, 99);
  --palette-brown-500: rgb(121, 85, 72);
  --palette-brown-600: rgb(109, 76, 65);
  --palette-brown-700: rgb(93, 64, 55);
  --palette-brown-800: rgb(78, 52, 46);
  --palette-brown-900: rgb(62, 39, 35);
  --palette-grey-50: rgb(250, 250, 250);
  --palette-grey-100: rgb(245, 245, 245);
  --palette-grey-200: rgb(238, 238, 238);
  --palette-grey-300: rgb(224, 224, 224);
  --palette-grey-400: rgb(189, 189, 189);
  --palette-grey-500: rgb(158, 158, 158);
  --palette-grey-600: rgb(117, 117, 117);
  --palette-grey-700: rgb(97, 97, 97);
  --palette-grey-800: rgb(66, 66, 66);
  --palette-grey-900: rgb(33, 33, 33);
  --palette-blue-grey-50: rgb(236, 239, 241);
  --palette-blue-grey-100: rgb(207, 216, 220);
  --palette-blue-grey-200: rgb(176, 190, 197);
  --palette-blue-grey-300: rgb(144, 164, 174);
  --palette-blue-grey-400: rgb(120, 144, 156);
  --palette-blue-grey-500: rgb(96, 125, 139);
  --palette-blue-grey-600: rgb(84, 110, 122);
  --palette-blue-grey-700: rgb(69, 90, 100);
  --palette-blue-grey-800: rgb(55, 71, 79);
  --palette-blue-grey-900: rgb(38, 50, 56);
  --color-black: rgb(0, 0, 0);
  --color-white: rgb(255, 255, 255);
  --color-dark-contrast: var(--color-white);
  --color-light-contrast: var(--color-black); }

:root {
  --color-divider: var(--palette-grey-200);
  --color-background: var(--color-white);
  --color-text: var(--palette-grey-900);
  --color-text-secondary: var(--palette-grey-600);
  --color-primary: var(--palette-indigo-500);
  --color-primary-dark: var(--palette-indigo-700);
  --color-accent: var(--palette-pink-a200);
  --color-accent-dark: var(--palette-pink-700);
  --color-primary-contrast: var(--color-dark-contrast);
  --color-accent-contrast: var(--color-dark-contrast);
  --unit: 10px;
  --preferred-font: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  --font-size: calc(1.6 * var(--unit));
  --font-size-tiny: calc(1.2 * var(--unit));
  --font-size-small: calc(1.4 * var(--unit));
  --font-size-normal: var(--font-size);
  --font-size-big: calc(1.8 * var(--unit));
  --font-weight-thin: 300;
  --font-weight-normal: 400;
  --font-weight-semi-bold: 500;
  --font-weight-bold: 700;
  --shadow-2p:
    0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  --shadow-3p:
    0 3px 4px 0 rgba(0, 0, 0, 0.14),
    0 3px 3px -2px rgba(0, 0, 0, 0.2),
    0 1px 8px 0 rgba(0, 0, 0, 0.12);
  --shadow-4p:
    0 4px 5px 0 rgba(0, 0, 0, 0.14),
    0 1px 10px 0 rgba(0, 0, 0, 0.12),
    0 2px 4px -1px rgba(0, 0, 0, 0.2);
  --shadow-6p:
    0 6px 10px 0 rgba(0, 0, 0, 0.14),
    0 1px 18px 0 rgba(0, 0, 0, 0.12),
    0 3px 5px -1px rgba(0, 0, 0, 0.2);
  --shadow-8p:
    0 8px 10px 1px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12),
    0 5px 5px -3px rgba(0, 0, 0, 0.2);
  --shadow-16p:
    0 16px 24px 2px rgba(0, 0, 0, 0.14),
    0 6px 30px 5px rgba(0, 0, 0, 0.12),
    0 8px 10px -5px rgba(0, 0, 0, 0.2);
  --shadow-key-umbra-opacity: 0.2;
  --shadow-key-penumbra-opacity: 0.14;
  --shadow-ambient-shadow-opacity: 0.12;
  --zdepth-shadow-1: 0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.24);
  --zdepth-shadow-2: 0 3px 10px rgba(0, 0, 0, 0.16), 0 3px 10px rgba(0, 0, 0, 0.23);
  --zdepth-shadow-3: 0 10px 30px rgba(0, 0, 0, 0.19), 0 6px 10px rgba(0, 0, 0, 0.23);
  --zdepth-shadow-4: 0 14px 45px rgba(0, 0, 0, 0.25), 0 10px 18px rgba(0, 0, 0, 0.22);
  --zdepth-shadow-5: 0 19px 60px rgba(0, 0, 0, 0.3), 0 15px 20px rgba(0, 0, 0, 0.22);
  --animation-duration: 0.35s;
  --animation-delay: calc(var(--animation-duration) / 5);
  --animation-curve-fast-out-slow-in: cubic-bezier(0.4, 0, 0.2, 1);
  --animation-curve-linear-out-slow-in: cubic-bezier(0, 0, 0.2, 1);
  --animation-curve-fast-out-linear-in: cubic-bezier(0.4, 0, 1, 1);
  --animation-curve-default: var(--animation-curve-fast-out-slow-in);
  --z-index-highest: 300;
  --z-index-higher: 200;
  --z-index-high: 100;
  --z-index-normal: 1;
  --z-index-low: -100;
  --z-index-lower: -200
; }

.dialog {
  overflow: auto; }

:global {
  /* Change Autocomplete styles in Chrome*/ }
  :global input:-webkit-autofill,
  :global input:-webkit-autofill:hover,
  :global input:-webkit-autofill:focus
textarea:-webkit-autofill,
  :global textarea:-webkit-autofill:hover
textarea:-webkit-autofill:focus,
  :global select:-webkit-autofill,
  :global select:-webkit-autofill:hover,
  :global select:-webkit-autofill:focus {
    box-shadow: 0 0 0px 1000px transparent inset;
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: #19496b; }
  :global .git-hash {
    position: absolute;
    bottom: 0;
    z-index: 10;
    right: 0;
    font-size: 16px;
    padding: 0 6px 2px 0; }
  :global .sm-snackbar {
    height: 3.5rem;
    width: 64%; }
    :global .sm-snackbar span, :global .sm-snackbar button {
      font-size: 14px;
      padding: 0 1.5rem; }
    :global .sm-snackbar button {
      font-size: 12px;
      height: 50px;
      line-height: 50px; }
  :global .cursor-pointer {
    cursor: pointer; }

.menu_item_selected > span {
  color: red;
  font-weight: bold; }

.main_container {
  display: flex;
  flex-flow: row nowrap;
  min-height: 15.4rem; }

.video_container {
  border-color: #49D1E0;
  border-style: solid;
  border-width: 1px; }

.tests_container {
  flex-shrink: 0;
  flex-grow: 0;
  width: 240px;
  margin-right: 40px; }
  .tests_container .title_container {
    display: flex;
    flex-flow: row nowrap;
    margin: 32px 0 16px 0; }
    .tests_container .title_container.yaml_tests_list {
      margin-top: 0; }
  .tests_container .title {
    display: inline-block;
    text-align: left;
    flex-grow: 1; }
    .tests_container .title h4 {
      font-family: 'Khand', sans-serif;
      font-weight: 500;
      font-size: 24px;
      width: 100%;
      display: inline-block;
      margin: 0;
      color: #0D5A91; }
  .tests_container .run_container {
    cursor: pointer;
    justify-self: flex-end;
    color: #929FB0;
    margin-top: 5px; }
    .tests_container .run_container > button {
      height: 19px; }
      .tests_container .run_container > button > svg > g > g > path {
        fill: #19496b; }
  .tests_container .tests_list {
    display: flex;
    gap: 8px;
    flex-direction: column; }
    .tests_container .tests_list .add_test_button {
      width: 239px;
      position: relative;
      text-align: center;
      border: .65px dashed #C5CBD3;
      background-color: #EAEDF2;
      color: #B4C0CF;
      font-size: 12px;
      cursor: pointer;
      opacity: .75; }
      .tests_container .tests_list .add_test_button button {
        height: 32px; }
      .tests_container .tests_list .add_test_button svg {
        width: 16px;
        height: 32px; }
        .tests_container .tests_list .add_test_button svg g > g > path {
          fill: #B4C0CF; }
      .tests_container .tests_list .add_test_button:hover {
        opacity: 1; }
    .tests_container .tests_list .edit_test_text {
      width: 233.5px;
      position: relative;
      text-align: left;
      border-left: 6px solid #D1D9E3;
      background-color: #fff;
      color: #B4C0CF;
      font-size: 12px;
      cursor: pointer; }
      .tests_container .tests_list .edit_test_text .test_name_input {
        padding: 0 0 0 8px;
        width: 200px; }
        .tests_container .tests_list .edit_test_text .test_name_input input {
          font-size: 12px;
          color: #19496b;
          padding: 8px 0 2px 0; }
        .tests_container .tests_list .edit_test_text .test_name_input label {
          font-size: 12px;
          color: #c5cbd3;
          top: 8px;
          left: 8px; }
        .tests_container .tests_list .edit_test_text .test_name_input > span:before, .tests_container .tests_list .edit_test_text .test_name_input > span:after {
          background-color: #c5cbd3; }
        .tests_container .tests_list .edit_test_text .test_name_input > span:last-child {
          display: none; }
      .tests_container .tests_list .edit_test_text .new_test_name {
        text-align: left;
        font-size: 12px;
        cursor: text;
        margin: 0 0 0 9px;
        padding: 0;
        color: #19496b;
        width: 200px;
        display: inline-block;
        height: 26px;
        line-height: 26px;
        border-bottom: .5px solid #D1D9E3; }
        .tests_container .tests_list .edit_test_text .new_test_name:before {
          content: "Enter a name for your test..."; }
        .tests_container .tests_list .edit_test_text .new_test_name:after {
          content: " ";
          background-image: url('data:image/svg+xml;charset=UTF-8,<svg fill="%23FEBD61" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"/><path d="M0 0h24v24H0z" fill="none"/></svg>');
          width: 12px;
          height: 12px;
          position: absolute;
          background-position: center;
          background-size: contain;
          top: 10px;
          right: 10px; }
      .tests_container .tests_list .edit_test_text .new_test_name_editing {
        font-size: 12px;
        color: #19496b;
        height: 26px;
        line-height: 26px;
        border: none;
        border-bottom: .5px solid #D1D9E3 !important;
        margin: 0 0 0 9px;
        padding: 0;
        width: 200px;
        outline: none;
        cursor: text; }
        .tests_container .tests_list .edit_test_text .new_test_name_editing:after {
          content: " ";
          background-image: url('data:image/svg+xml;charset=UTF-8,<svg fill="%23FEBD61" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"/><path d="M0 0h24v24H0z" fill="none"/></svg>');
          width: 12px;
          height: 12px;
          position: absolute;
          background-position: center;
          background-size: contain;
          top: 10px;
          right: 10px; }

.sortable_list {
  cursor: grabbing; }
  .sortable_list *:last-child > div {
    margin-bottom: unset; }

.drag_sortable {
  cursor: grab;
  position: relative; }
  .drag_sortable > span {
    height: 33px;
    width: 226px;
    position: absolute;
    left: 0px;
    z-index: 1;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    top: -1px; }
  .drag_sortable.grabbing {
    cursor: grabbing; }

.test_menu_divider {
  background-color: #eeeeee;
  border: 0;
  display: block;
  height: 1px;
  margin: 3px 0;
  outline: 0;
  padding: 0;
  width: 100%; }

.test_suite_description {
  min-width: 400px; }
  .test_suite_description > [class~="component"] > textarea:focus {
    background-color: white; }

.other_fields {
  display: grid;
  gap: 4px 0px;
  grid-template-columns: 1fr;
  padding: 8px 12px;
  border: 1px solid #49D1E0;
  background-color: #FFFFFF; }
  .other_fields > div {
    display: grid;
    align-items: center;
    gap: 4px 0px;
    grid-template-columns: 1fr auto; }
    .other_fields > div .info_icon {
      height: 16px;
      width: 16px;
      line-height: 16px;
      display: grid; }
      .other_fields > div .info_icon * {
        font-size: 16px;
        line-height: 16px;
        height: 16px;
        width: 16px; }
    .other_fields > div > :last-child {
      grid-column: 1/-1; }

.sortable_item_container {
  margin-bottom: 8px; }

.sortable_item {
  width: 225px;
  height: 32px;
  border: .5px solid #B4C0CF;
  border-radius: 3px;
  font-size: 12px; }
  .sortable_item.item, .sortable_item.selected {
    position: relative;
    line-height: 32px;
    border-left: 6px solid;
    padding-left: 8px; }
    .sortable_item.item > span, .sortable_item.selected > span {
      display: inline-block;
      font-size: 12px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding-right: 16px;
      width: 208.5px; }
    .sortable_item.item .test_name_tooltip, .sortable_item.selected .test_name_tooltip {
      height: 32px; }
      .sortable_item.item .test_name_tooltip > span, .sortable_item.selected .test_name_tooltip > span {
        display: inline-block;
        font-size: 12px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding-right: 16px;
        width: 208.5px; }
    .sortable_item.item .test_flag, .sortable_item.selected .test_flag {
      top: 2px;
      position: absolute;
      right: 20px;
      font-size: 16px; }
    .sortable_item.item .test_flag_icon, .sortable_item.selected .test_flag_icon {
      font-size: 16px; }
    .sortable_item.item .test_menu, .sortable_item.selected .test_menu {
      top: 0;
      position: absolute;
      right: 0;
      margin-right: 2px;
      height: 16px; }
      .sortable_item.item .test_menu > button, .sortable_item.selected .test_menu > button {
        width: 16px;
        height: 16px; }
        .sortable_item.item .test_menu > button > svg, .sortable_item.selected .test_menu > button > svg {
          height: 16px;
          width: 4px; }
          .sortable_item.item .test_menu > button > svg rect, .sortable_item.selected .test_menu > button > svg rect {
            fill: #B4C0CF; }
      .sortable_item.item .test_menu > div, .sortable_item.selected .test_menu > div {
        width: 160px !important;
        min-height: 150px; }
        .sortable_item.item .test_menu > div > div, .sortable_item.selected .test_menu > div > div {
          width: 160px !important;
          min-height: 150px !important;
          background-color: #FFFFFF;
          border-radius: 5px; }
        .sortable_item.item .test_menu > div > ul, .sortable_item.selected .test_menu > div > ul {
          position: relative !important;
          margin: 0 !important;
          padding: 8px; }
          .sortable_item.item .test_menu > div > ul > li, .sortable_item.selected .test_menu > div > ul > li {
            height: 32px;
            padding: 8px 8px 8px 8px; }
            .sortable_item.item .test_menu > div > ul > li > span, .sortable_item.selected .test_menu > div > ul > li > span {
              font-size: 12px;
              color: #939598; }
            .sortable_item.item .test_menu > div > ul > li:first-child, .sortable_item.selected .test_menu > div > ul > li:first-child {
              white-space: normal; }
            .sortable_item.item .test_menu > div > ul > li:hover, .sortable_item.selected .test_menu > div > ul > li:hover {
              background-color: #f5f8fc;
              border-radius: 5px; }
            .sortable_item.item .test_menu > div > ul > li:last-child span, .sortable_item.selected .test_menu > div > ul > li:last-child span {
              color: red; }
    .sortable_item.item .status_icon, .sortable_item.selected .status_icon {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      position: absolute;
      left: -32px;
      top: 12px; }
      .sortable_item.item .status_icon.running, .sortable_item.selected .status_icon.running {
        background-color: #49D1E0;
        animation: pulse 1.5s ease-in-out infinite; }
      .sortable_item.item .status_icon.loading, .sortable_item.selected .status_icon.loading {
        background-color: #B4C0CF;
        animation: fade 1.5s ease-in-out infinite; }
      .sortable_item.item .status_icon.success, .sortable_item.selected .status_icon.success {
        background-color: #65DBAE; }
      .sortable_item.item .status_icon.error, .sortable_item.selected .status_icon.error {
        background-color: #FF7D7D; }

@keyframes pulse {
  0% {
    transform: scale(0.85);
    box-shadow: 0 0 0 0 rgba(73, 209, 224, 0.3); }
  70% {
    transform: scale(0.9);
    box-shadow: 0 0 0 8px rgba(73, 209, 224, 0.3); }
  100% {
    transform: scale(0.85);
    box-shadow: 0 0 0 0 rgba(73, 209, 224, 0.3); } }

@keyframes fade {
  0%, 100% {
    opacity: 0.4; }
  50% {
    opacity: 1; } }
    .sortable_item.item .test_name_input, .sortable_item.selected .test_name_input {
      padding: 0;
      width: 200px; }
      .sortable_item.item .test_name_input > input, .sortable_item.selected .test_name_input > input {
        font-size: 12px;
        color: #fff;
        padding: 8px 0 2px 0; }
      .sortable_item.item .test_name_input > label, .sortable_item.selected .test_name_input > label {
        font-size: 12px;
        color: #c5cbd3;
        top: 8px;
        left: 8px; }
      .sortable_item.item .test_name_input > span:before, .sortable_item.item .test_name_input > span:after, .sortable_item.selected .test_name_input > span:before, .sortable_item.selected .test_name_input > span:after {
        background-color: #c5cbd3; }
      .sortable_item.item .test_name_input > span:last-child, .sortable_item.selected .test_name_input > span:last-child {
        display: none; }
    .sortable_item.item.loading, .sortable_item.selected.loading {
      background-color: #E6EEF4;
      color: #4C85AD;
      border-color: #4C85AD; }
      .sortable_item.item.loading .test_menu > button > svg rect, .sortable_item.selected.loading .test_menu > button > svg rect {
        fill: transparent; }
      .sortable_item.item.loading.skipped, .sortable_item.selected.loading.skipped {
        border-color: #B4C0CF; }
  .sortable_item.item {
    cursor: pointer;
    background-color: #fff;
    color: #B4C0CF;
    border-color: #B4C0CF; }
    .sortable_item.item.success {
      border-color: #65DBAE;
      background-color: #fff;
      color: #B4C0CF; }
      .sortable_item.item.success .test_menu > button > svg rect {
        fill: #B4C0CF; }
      .sortable_item.item.success.skipped {
        border-color: #B4C0CF; }
    .sortable_item.item.error {
      border-color: #FF7D7D;
      background-color: #fff;
      color: #B4C0CF; }
      .sortable_item.item.error .test_menu > button > svg rect {
        fill: #B4C0CF; }
      .sortable_item.item.error.skipped {
        border-color: #B4C0CF; }
    .sortable_item.item .test_name_input > input {
      font-size: 12px;
      color: #B4C0CF;
      padding: 8px 0 2px 0; }
  .sortable_item.selected {
    background-color: #19496b;
    color: #fff;
    border-color: #4AD1E0;
    border-bottom-left-radius: unset;
    border-bottom-right-radius: unset; }
    .sortable_item.selected > div > span {
      color: #fff; }
    .sortable_item.selected .test_menu > button > svg rect {
      fill: #fff; }
    .sortable_item.selected.success {
      border-color: #65DBAE; }
      .sortable_item.selected.success > div > span {
        color: #fff; }
      .sortable_item.selected.success .test_menu > button > svg rect {
        fill: #B4C0CF; }
      .sortable_item.selected.success.skipped {
        border-color: #B4C0CF; }
    .sortable_item.selected.error {
      border-color: #FF7D7D; }
      .sortable_item.selected.error > div > span {
        color: #fff; }
      .sortable_item.selected.error .test_menu > button > svg rect {
        fill: #B4C0CF; }
      .sortable_item.selected.error.skipped {
        border-color: #B4C0CF; }
    .sortable_item.selected.loading {
      background-color: #19496b;
      border-color: #4C85AD; }
      .sortable_item.selected.loading > div > span {
        color: #fff; }
      .sortable_item.selected.loading .test_menu > button > svg rect {
        fill: transparent; }

.button_container {
  display: inline-block; }
  .button_container.right {
    text-align: right;
    margin-left: calc(66.6666666667% + 8px); }
  .button_container.left {
    margin-left: 36px;
    width: calc(16.6666666667% - 54px); }
    .button_container.left button {
      width: 100%; }

.dropdown_container {
  display: flex; }
  .dropdown_container > div {
    display: flex;
    flex-flow: row;
    align-items: center;
    margin-right: 1rem;
    align-items: flex-end; }
    .dropdown_container > div > div,
    .dropdown_container > div > button {
      width: 218px;
      margin-right: 16px; }
      .dropdown_container > div > div > button.create_virtual_device,
      .dropdown_container > div > button > button.create_virtual_device {
        font-size: 14px !important;
        width: 185px; }
      .dropdown_container > div > div > div.virtual_devices,
      .dropdown_container > div > button > div.virtual_devices {
        width: 210px;
        margin-right: 16px; }
    .dropdown_container > div > div {
      flex-grow: 1;
      white-space: nowrap; }
      .dropdown_container > div > div:last-child {
        width: auto; }
      .dropdown_container > div > div > span {
        font-size: 14px;
        color: #0D5A91;
        margin-bottom: 8px;
        display: block; }
      .dropdown_container > div > div .refresh_token,
      .dropdown_container > div > div .copy_token,
      .dropdown_container > div > div .advance_settings {
        display: inline-block;
        text-decoration: none; }
        .dropdown_container > div > div .refresh_token button,
        .dropdown_container > div > div .copy_token button,
        .dropdown_container > div > div .advance_settings button {
          position: relative;
          top: 1px;
          width: 2.6rem;
          height: 2.6rem;
          font-size: 1.4rem;
          line-height: 3.6rem;
          padding: 1px 6px; }
          .dropdown_container > div > div .refresh_token button svg,
          .dropdown_container > div > div .copy_token button svg,
          .dropdown_container > div > div .advance_settings button svg {
            vertical-align: middle; }
            .dropdown_container > div > div .refresh_token button svg path,
            .dropdown_container > div > div .copy_token button svg path,
            .dropdown_container > div > div .advance_settings button svg path {
              fill: #19496b; }
          .dropdown_container > div > div .refresh_token button > span,
          .dropdown_container > div > div .copy_token button > span,
          .dropdown_container > div > div .advance_settings button > span {
            line-height: 1;
            vertical-align: top;
            margin-top: 7px;
            color: #19496b; }
        .dropdown_container > div > div .refresh_token.active button svg path,
        .dropdown_container > div > div .copy_token.active button svg path,
        .dropdown_container > div > div .advance_settings.active button svg path {
          fill: #65DBAE; }
        .dropdown_container > div > div .refresh_token.active button svg g,
        .dropdown_container > div > div .copy_token.active button svg g,
        .dropdown_container > div > div .advance_settings.active button svg g {
          fill: #65DBAE; }
      .dropdown_container > div > div .advance_settings > button {
        padding-left: 0;
        top: -9px;
        left: -6px; }
      .dropdown_container > div > div .copy_token {
        width: 1.8rem; }
        .dropdown_container > div > div .copy_token button {
          top: 0; }
          .dropdown_container > div > div .copy_token button span {
            font-size: 1.25rem !important;
            top: -2px; }
      .dropdown_container > div > div.twilio > div {
        padding: 0; }
      .dropdown_container > div > div.twilio > span {
        font-size: 14px;
        color: #0D5A91;
        margin-bottom: 8px;
        display: block; }
      .dropdown_container > div > div.twilio input {
        background-color: #fff;
        font-size: 14px;
        line-height: 22px; }
      .dropdown_container > div > div.create_vd_container {
        display: flex;
        flex-flow: row nowrap;
        align-items: flex-end;
        margin-top: 0; }
        .dropdown_container > div > div.create_vd_container > button {
          margin-right: 16px; }
        .dropdown_container > div > div.create_vd_container > div > div {
          padding: 0; }
        .dropdown_container > div > div.create_vd_container > div > span {
          font-size: 14px;
          color: #0D5A91;
          margin-bottom: 8px;
          display: block; }
        .dropdown_container > div > div.create_vd_container > div input {
          background-color: #fff;
          font-size: 14px;
          line-height: 22px; }
        .dropdown_container > div > div.create_vd_container > div.actions {
          display: flex;
          align-items: flex-end; }
    .dropdown_container > div > span {
      justify-self: flex-end; }
      .dropdown_container > div > span.virtual_device_text {
        display: block;
        height: 40px;
        line-height: 40px;
        padding-left: 12px; }
    .dropdown_container > div:nth-child(2) {
      position: relative; }
      .dropdown_container > div:nth-child(2) > div:first-child {
        margin-bottom: 5px; }
      .dropdown_container > div:nth-child(2) > div:last-child {
        position: absolute;
        right: -60px;
        bottom: 8px; }

.token_container {
  display: flex;
  width: 100%;
  height: 1.8rem; }
  .token_container > span {
    display: flex;
    align-items: center;
    white-space: nowrap;
    margin-right: 1rem;
    width: 11rem; }
  .token_container > div {
    flex-grow: 1;
    display: flex;
    align-items: center; }
    .token_container > div label {
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }

.token_input {
  display: inline-block; }

.validation_text {
  display: inline-block;
  margin: 0 28px 20px 28px;
  color: #E69198; }

.get_token {
  padding: 0 !important;
  font-size: 1.14rem;
  margin: .75rem 10px; }

.info_button {
  position: absolute;
  top: 5px;
  left: 100%;
  width: 1.8rem;
  height: 1.8rem; }
  .info_button.yaml {
    top: 3px; }
  .info_button button {
    padding: 0;
    width: 1.8rem;
    height: 1.8rem; }
    .info_button button span {
      display: block;
      height: 16px;
      font-size: 1.14rem;
      line-height: 1;
      color: #949494; }

.not_supported_overlay {
  position: absolute;
  width: 100%;
  bottom: 0;
  top: 11rem;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center; }
  .not_supported_overlay span {
    font-size: 2rem;
    color: #fff; }
  .not_supported_overlay div {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    background-color: #777;
    opacity: .8;
    z-index: -1; }

.yaml_title_container {
  position: relative;
  margin: 55px 8px 0 8px;
  display: flex; }
  .yaml_title_container div {
    width: 352px;
    text-align: left; }
    .yaml_title_container div strong {
      color: #19496b;
      font-family: 'Khand', sans-serif;
      font-size: 24px;
      font-weight: 400; }
    .yaml_title_container div + div {
      background-color: #525460;
      width: auto;
      flex-grow: 1;
      text-align: right;
      position: absolute;
      left: 368px;
      top: 38px;
      right: 0;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      padding: 0 14px 6px 0;
      z-index: 1; }
      .yaml_title_container div + div button > svg > path {
        fill: #fff; }
      .yaml_title_container div + div a {
        font-size: 12px;
        color: #fff; }
    .yaml_title_container div button {
      width: 17.32px;
      height: 13.18px;
      margin-right: 8.64px; }
  .yaml_title_container a {
    color: #929FB0;
    font-size: 14px;
    text-decoration: none; }

.yaml_editor_container {
  position: relative;
  margin: 0;
  width: 100%;
  display: flex; }
  .yaml_editor_container button {
    position: absolute;
    bottom: -84px;
    right: 0;
    background-color: #4A4B4D;
    color: #fff;
    font-size: 1.5rem;
    border: none;
    margin: 0 0 5px 5px;
    width: 13%;
    padding: 5px 0;
    font-family: 'Khand', sans-serif; }
    .yaml_editor_container button + button {
      width: 15%;
      right: 11rem; }
    .yaml_editor_container button[disabled] {
      background-color: #9D9D9D; }
    .yaml_editor_container button .run_yaml_button {
      height: 32px !important; }
      .yaml_editor_container button .run_yaml_button circle {
        stroke: #fff; }
  .yaml_editor_container > span {
    position: absolute;
    bottom: -24px;
    width: 100%;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
    .yaml_editor_container > span.success {
      color: #65DBAE; }
    .yaml_editor_container > span.error {
      color: #FF7D7D; }
  .yaml_editor_container .ace_editor {
    background-color: #525460;
    flex-grow: 1;
    margin-left: 16px;
    display: inline-block;
    height: calc(44vh - 3.928571428571429rem - 33px) !important;
    border-radius: 8px;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    margin-top: 25px;
    z-index: 0; }
    .yaml_editor_container .ace_editor :global .ace_gutter {
      background-color: #525460; }
      .yaml_editor_container .ace_editor :global .ace_gutter .ace_gutter-cell:first-child {
        background-color: #525460; }
      .yaml_editor_container .ace_editor :global .ace_gutter .ace_gutter-active-line {
        background-color: #525460; }
    .yaml_editor_container .ace_editor :global .ace_scroller .ace_active-line {
      background-color: #525460; }

.right_button_panel {
  position: relative;
  margin-right: 0;
  margin-left: 16px; }
  .right_button_panel.yaml_title {
    right: -40%;
    z-index: 1; }

.validation_row {
  display: flex;
  flex-flow: row wrap;
  padding: 0 0 20px;
  position: relative; }
  .validation_row .img_container {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    flex-grow: 0;
    padding-right: 26px;
    margin-bottom: 20px; }
    .validation_row .img_container img {
      width: 51.5px;
      height: 51.5px;
      margin: -60px 18px 0 0; }
    .validation_row .img_container .name_editor {
      margin-top: -60px;
      z-index: 1; }
  .validation_row .name_container {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: flex-start;
    flex-grow: 1;
    padding-left: 0;
    z-index: 3; }

.unblock {
  display: none; }

.block {
  display: block;
  position: absolute;
  top: -65px;
  right: 0;
  left: 0;
  height: 176px;
  z-index: 2; }

.yaml_switch_container {
  position: relative; }
  .yaml_switch_container > span {
    color: #929FB0;
    font-size: 14px; }
    .yaml_switch_container > span.enabled {
      color: #65DBAE; }
    .yaml_switch_container > span.disabled {
      color: #FF7D7D; }
  .yaml_switch_container > label {
    top: -1px; }
  .yaml_switch_container .info_button {
    position: absolute;
    left: 162px;
    top: -3px; }
    .yaml_switch_container .info_button button span {
      display: block;
      line-height: 1;
      color: #B4C0CF; }

.top_right_container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-content: center;
  justify-items: start;
  gap: 16px; }

.monitoring_text_container {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  margin-bottom: 10px;
  margin-right: 13px; }
  .monitoring_text_container.enabled > span {
    color: #65DBAE; }
  .monitoring_text_container.enabled button svg path {
    fill: #65DBAE; }
  .monitoring_text_container > span {
    width: 102px;
    text-align: right;
    color: #FF7D7D;
    flex-grow: 0;
    flex-shrink: 0;
    font-size: 14px; }
  .monitoring_text_container > label {
    top: -1px; }
  .monitoring_text_container button {
    width: 25px;
    margin-right: 25px;
    flex-grow: 0;
    flex-shrink: 0; }
    .monitoring_text_container button svg {
      width: 25px; }
      .monitoring_text_container button svg path {
        fill: #FF7D7D; }
  .monitoring_text_container .info_button {
    position: absolute;
    left: 162px; }
    .monitoring_text_container .info_button button span {
      display: block;
      line-height: 1;
      color: #B4C0CF; }

.right_bottom_actions {
  position: absolute;
  bottom: 32px;
  right: 0px;
  z-index: 0; }
  .right_bottom_actions a {
    font-size: 12px;
    color: #929FB0;
    margin-left: 11px; }
  .right_bottom_actions button {
    width: 22px;
    height: 18px;
    margin-top: -2px;
    margin-right: 2px; }
    .right_bottom_actions button svg {
      width: 18px;
      height: 18px;
      vertical-align: top; }
      .right_bottom_actions button svg path {
        fill: #19496b; }
    .right_bottom_actions button > span {
      line-height: 1;
      vertical-align: top;
      margin-top: 7px;
      color: #19496b; }
  .right_bottom_actions .advance_settings > button {
    margin-right: 0; }
    .right_bottom_actions .advance_settings > button svg {
      width: 22px; }
  .right_bottom_actions .advance_settings.active button svg path {
    fill: #65DBAE; }
  .right_bottom_actions .advance_settings.active button svg g {
    fill: #65DBAE; }

.buttons_container {
  padding: 8px 8px 0 8px;
  flex-grow: 1;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .buttons_container .yamlEditor {
    width: calc(67% - 286px); }
    .buttons_container .yamlEditor a button {
      font-size: 14px;
      color: #929FB0 !important;
      text-transform: none;
      font-weight: normal;
      font-family: 'Open Sans', sans-serif; }
      .buttons_container .yamlEditor a button:hover {
        background-color: transparent !important; }
      .buttons_container .yamlEditor a button span {
        line-height: 0;
        vertical-align: text-bottom; }
      .buttons_container .yamlEditor a button svg {
        width: 22.19px;
        height: 17px; }
        .buttons_container .yamlEditor a button svg path {
          fill: #116099; }
  .buttons_container .check_syntax {
    text-align: left;
    flex-grow: 1; }
    .buttons_container .check_syntax button {
      margin-left: -5px;
      padding: 0;
      background-color: transparent !important;
      border: none !important;
      text-transform: none;
      font-weight: normal; }
      .buttons_container .check_syntax button:hover {
        background-color: transparent !important; }
      .buttons_container .check_syntax button span {
        vertical-align: text-bottom; }
      .buttons_container .check_syntax button span + span {
        font-size: 14px;
        color: #929FB0; }
      .buttons_container .check_syntax button svg {
        width: 17.88px;
        height: 17px; }
        .buttons_container .check_syntax button svg path {
          fill: #116099; }
      .buttons_container .check_syntax button:disabled svg {
        width: 17.88px;
        height: 17px; }
        .buttons_container .check_syntax button:disabled svg path {
          fill: #116099 !important; }
  .buttons_container button {
    width: 128px;
    margin-left: 8px; }
    .buttons_container button span svg {
      margin-top: -5px;
      height: 18px; }
    .buttons_container button + button span {
      margin-right: 9.3px !important; }
    .buttons_container button + button + button span {
      margin-right: 8px !important; }
    .buttons_container button.cancel {
      background-color: #FF7D7D !important;
      border: 1px solid #FF7D7D; }
      .buttons_container button.cancel span {
        color: #fff; }
        .buttons_container button.cancel span svg {
          width: 18px; }

.dialog_buttons_container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end; }
  .dialog_buttons_container button {
    margin-left: 5px; }

.test_dialog_buttons_container {
  margin: 32px 0 23px 0;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end; }
  .test_dialog_buttons_container button {
    margin-left: 5px; }

.dialog_title {
  margin-top: 0; }

.advance_container {
  display: flex;
  flex-flow: column;
  padding: 0 30px;
  height: calc(100% - 30px); }
  .advance_container > h4 {
    color: #19496b; }
  .advance_container > div {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    width: calc(100% - 50px); }
    .advance_container > div > span {
      font-size: 14px;
      width: 180px; }
    .advance_container > div > div {
      flex-shrink: 1;
      flex-grow: 1; }
    .advance_container > div > label {
      margin-bottom: 0; }
    .advance_container > div:last-child {
      margin-top: 20px;
      flex-flow: row wrap; }
      .advance_container > div:last-child > div {
        width: 100%; }
    .advance_container > div .drawerInfoTooltip {
      position: absolute;
      right: -50px;
      width: auto !important; }
      .advance_container > div .drawerInfoTooltip button {
        color: rgba(0, 0, 0, 0.26) !important; }
      .advance_container > div .drawerInfoTooltip.additional {
        top: -5px; }
    .advance_container > div:nth-last-child(2) {
      flex-grow: 1; }
    .advance_container > div.additional_settings {
      display: flex;
      flex-flow: column; }
      .advance_container > div.additional_settings > div:first-child {
        flex-grow: 0; }
      .advance_container > div.additional_settings > div:first-child,
      .advance_container > div.additional_settings > div:nth-child(2) {
        width: 100%; }
    .advance_container > div.advance_settings_bottom {
      margin-top: auto;
      margin-bottom: 10px;
      justify-content: flex-end; }
      .advance_container > div.advance_settings_bottom > button {
        margin-left: 10px; }
    .advance_container > div .drawer_input {
      width: 200px; }
      .advance_container > div .drawer_input > input,
      .advance_container > div .drawer_input > label,
      .advance_container > div .drawer_input > span {
        font-size: 14px; }
  .advance_container .additionalSettings {
    flex-grow: 0; }
    .advance_container .additionalSettings textarea {
      font-size: 14px; }
  .advance_container .additionalSettingsError {
    position: relative;
    top: -10px;
    left: 2px;
    align-self: flex-start;
    color: #FF7D7D; }
  .advance_container input:disabled,
  .advance_container textarea:disabled {
    background-color: rgba(197, 203, 211, 0.2); }
  .advance_container .lenientMode_container {
    margin-top: 28px; }
  .advance_container .speechModel_container {
    margin-top: 20px; }
  .advance_container .recordCall_container {
    margin-top: 20px; }
  .advance_container .repeatonphrases_container {
    margin-top: 28px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: stretch; }

.drawer {
  width: 600px; }

.disabled {
  color: #B4C0CF !important; }

:global .ace_editor,
:global .ace_editor div,
:global .ace_editor span {
  font-family: monospace;
  font-size: 14px; }

@media (max-width: 1395px) {
  .dropdown_container > div > div.virtual_devices,
  .dropdown_container > div > button.virtual_devices {
    width: 190px; }
    .dropdown_container > div > div.virtual_devices > div > div > div > div > div,
    .dropdown_container > div > button.virtual_devices > div > div > div > div > div {
      overflow: hidden;
      text-overflow: ellipsis; } }

@media (max-width: 1375px) {
  .dropdown_container > div > div.virtual_devices,
  .dropdown_container > div > button.virtual_devices {
    width: 184px; }
    .dropdown_container > div > div.virtual_devices > div > div > div > div > div,
    .dropdown_container > div > button.virtual_devices > div > div > div > div > div {
      overflow: hidden;
      text-overflow: ellipsis; } }

@media (max-width: 1366px) {
  .yaml_editor_container .ace_editor {
    height: calc(31vh - 3.928571428571429rem - 8px) !important; }
  .dropdown_container > div > div.virtual_devices,
  .dropdown_container > div > button.virtual_devices {
    width: 180px; }
    .dropdown_container > div > div.virtual_devices > div > div > div > div > div,
    .dropdown_container > div > button.virtual_devices > div > div > div > div > div {
      overflow: hidden;
      text-overflow: ellipsis; } }

@media (max-width: 840px) {
  .button_container.right {
    margin-left: calc(59.666667% + 8px); }
  .right_button_panel.yaml_title {
    right: -80%; }
  .yaml_editor_container button + button {
    width: 21%; } }

@media (width: 840px) {
  .right_button_panel.yaml_title {
    right: -40%; } }

@media (max-width: 667px) {
  .yaml_editor_container button + button {
    width: 26%; } }

@media (max-width: 478px) {
  .button_container.right {
    margin-left: calc(34.666667% + 8px); } }
