/** Below styles are legacy needs to be refactored **/
.container {
  display: grid;
  margin: 32px;
  grid-template-columns: 400px auto;
  gap: 24px; }
  .container .title {
    grid-column: 1 / -1; }

.ControlSection {
  display: flex;
  flex-direction: column;
  padding: 16px 24px; }

.ControllerSection {
  display: grid;
  grid-template-columns: 1fr 2fr;
  border: 1px solid #939598;
  padding: 16px 24px; }
