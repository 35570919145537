.menu {
  background-color: transparent;
  border-radius: 5px;
  margin-top: 30px; }

.menuInner {
  padding: 0px;
  background-color: transparent;
  border-radius: 5px; }

.outline {
  background-color: transparent;
  border-radius: 5px; }

.iconMenu svg {
  cursor: pointer;
  height: 24px; }

.icon {
  display: grid !important; }
