.leftPanel {
  position: relative;
  display: flex;
  color: white;
  flex-direction: column;
  line-height: normal;
  align-items: center;
  text-align: center; }
  @media only screen and (max-width: 64rem) {
    .leftPanel {
      display: none; } }
  .leftPanel [data-id="details"] {
    position: absolute;
    top: 15%; }
  .leftPanel div {
    font-family: 'Khand'; }
  .leftPanel [data-id="icon"] {
    width: 35px;
    border: 1px solid white;
    border-radius: 50%;
    padding: 5px;
    margin-bottom: 20px; }
  .leftPanel [data-id="message1"] {
    font-family: 'Raleway';
    font-size: 21px;
    color: #fff;
    margin-bottom: 28px; }
  .leftPanel [data-id="message2"], .leftPanel [data-id="message3"] {
    font-size: 42px;
    text-transform: uppercase;
    color: #fff;
    line-height: 100%; }
  .leftPanel [data-id="message3"] {
    font-weight: bold;
    color: #fff;
    margin-top: 5px; }
  .leftPanel [data-id="companyName"] {
    position: fixed;
    font-size: 28px;
    bottom: 10px;
    color: #fff; }

.rightPanel {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  position: relative; }
  @media only screen and (max-width: 64rem) {
    .rightPanel {
      width: 100vw !important; } }
  .rightPanel [data-id="icon"] {
    height: 60px;
    margin-bottom: 50px; }
    .rightPanel [data-id="icon"] path:last-child {
      fill: #19496b !important; }
  .rightPanel section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
  .rightPanel [data-id="mountains"] {
    position: fixed;
    bottom: 0;
    z-index: -1;
    width: 50%; }
    @media only screen and (max-width: 64rem) {
      .rightPanel [data-id="mountains"] {
        width: 100%; } }

.link {
  font-size: 12px; }

.main_form {
  text-align: center;
  display: flex;
  justify-content: center; }

.dialog_buttons_container {
  margin: 23px 0 23px 0;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end; }
  .dialog_buttons_container button {
    margin-left: 5px; }
