.bar {
  visibility: hidden; }

.input {
  padding: 0; }

.inputElement {
  font-size: 16px;
  border: 1px solid #c5cbd3;
  color: #0d5a91;
  border-radius: 5px;
  padding: 8px 10px;
  width: 100%; }
  .inputElement:focus {
    border-color: #2577b0; }
  .inputElement::placeholder {
    color: #c5cbd3; }

.label {
  display: none; }

.counter {
  display: none; }

.disabled {
  background-color: #f4f7fc; }
  .disabled .inputElement {
    border-style: solid; }
