.container {
  height: 100vh;
  display: grid;
  grid-template-rows: auto 300px auto;
  grid-template-columns: auto 300px auto; }
  .container .loading_icon {
    grid-column: 2;
    grid-row: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 24px; }
