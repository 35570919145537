.audio_player {
  display: flex;
  flex-direction: column;
  gap: 14px;
  width: 200px; }
  .audio_player .header {
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    color: #19496B;
    font-size: 12px; }
  .audio_player .controls {
    display: grid;
    gap: 12px;
    grid-template-columns: auto 1fr auto auto;
    align-items: center; }
    .audio_player .controls span {
      display: flex;
      justify-content: flex-end;
      color: #19496B;
      font-size: 12px; }
    .audio_player .controls .hide {
      display: none; }
  .audio_player .play {
    position: relative;
    z-index: 1; }
  .audio_player svg {
    cursor: pointer;
    width: 18px;
    height: 18px; }
    .audio_player svg path {
      fill: #19496B; }
  .audio_player .disable {
    cursor: default; }
    .audio_player .disable path {
      fill: #C5CBD3; }
