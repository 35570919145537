.rightPanel {
  width: 50%;
  float: right; }
  .rightPanel.fullHeight {
    height: 100vh; }
  .rightPanel.image {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center; }
  .rightPanel [data-id="container"] {
    width: 100%;
    overflow-y: auto;
    padding-top: 20px;
    padding-bottom: 20px; }
