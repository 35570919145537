.dialog {
  width: 800px; }
  .dialog .ace_editor {
    font-size: "10px";
    width: "800px"; }
  .dialog .ace_editor div {
    font-size: "10px"; }
  .dialog section div {
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px; }

.ace_editor {
  font-size: "10px";
  width: "800px"; }
