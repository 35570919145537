.title {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 25px;
  color: #19496B; }

.layout {
  display: grid;
  grid-template-columns: minmax(70px, auto) 1fr minmax(40px, auto);
  grid-gap: 10px;
  grid-auto-rows: minmax(10px, auto); }
  .layout .test_status {
    text-align: center; }
  .layout .test_name_title {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    color: #19496B;
    display: flex;
    align-items: center; }
  .layout .suite_name_title {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 25px;
    color: #19496B;
    display: flex;
    align-items: center; }
  .layout .test_counters {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    color: #19496B;
    text-align: right; }
  .layout .suite_status_passed {
    background-color: rgba(0, 188, 117, 0.5);
    display: grid;
    padding: 5px 10px;
    justify-content: center;
    align-items: center;
    min-width: 100px; }
    .layout .suite_status_passed > span {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      color: #19496B; }
  .layout .suite_status_failed {
    background-color: rgba(255, 125, 125, 0.5);
    display: grid;
    padding: 5px 10px;
    justify-content: center;
    align-items: center;
    min-width: 100px; }
    .layout .suite_status_failed > span {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      color: #19496B; }
  .layout .suite_status_skipped {
    background-color: rgba(255, 222, 58, 0.5);
    display: grid;
    padding: 5px 10px;
    justify-content: center;
    align-items: center;
    min-width: 100px; }
    .layout .suite_status_skipped > span {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      color: #19496B; }
