.container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  gap: 16px;
  width: 100%; }
  .container > * {
    min-height: 160px;
    box-sizing: border-box; }
    .container > *:hover {
      box-shadow: 0px 4px 1px 0px rgba(0, 0, 0, 0.11); }
