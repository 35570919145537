/** Below styles are legacy needs to be refactored **/
.component2 {
  background-color: #fff;
  height: 32px;
  padding: 0px; }
  .component2 input {
    font-size: 12px;
    color: #19496B;
    padding-left: 8px; }
  .component2 > div {
    padding: 0; }
  .component2 ul {
    margin-top: 32px; }
    .component2 ul li {
      display: flex;
      align-items: center;
      padding: 0px;
      font-size: 12px;
      height: 32px;
      padding-left: 8px; }
    .component2 ul li[class^="theme__selected"] {
      color: #19496B; }
