.field {
  margin-bottom: 0; }

.radio {
  border-color: #c5cbd3;
  border-width: 1px; }
  .radio::before {
    background-color: #0d5a91; }

.radioChecked {
  border-color: #c5cbd3;
  border-width: 1px; }
  .radioChecked::before {
    background-color: #0d5a91; }
