/** Below styles are legacy needs to be refactored **/
/** Below styles are legacy needs to be refactored **/
/** Below styles are legacy needs to be refactored **/
.control {
  display: flex;
  gap: 4px;
  flex-direction: column; }
  .control > textarea {
    font-family: "Open Sans";
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: #939598;
    color: #0d5a91;
    resize: none;
    outline: none;
    padding: 10px 8px;
    border-radius: 5px;
    border: 1px solid #C5CBD3; }
    .control > textarea:focus {
      border-color: #0d5a91; }

.error textarea {
  border-color: #FF8280; }
